import { Box } from "@mui/system";
import React from "react";
import './styles.scss'

const Title = (props) => {

    return (
        <Box className="title">
            <h3>{props.children}</h3>
        </Box>
    )
}

export default Title;


import { pubRequest } from "../../api/PublicRequest";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setData, error, logout, login, initLogin, stoppingLogin } from './actions'
import { showAlert } from "../alert/reducer";
 
const initialState = {
    token: window.localStorage.getItem('authToken'),
    user: undefined,
    logging: false,
    error: ''
}

export const loginUser = createAsyncThunk('user/login', async (data, thunkApi) => {
    try {
        thunkApi.dispatch(initLogin())
        const response = await pubRequest("api/auth/login", 'POST', data);

        if (response.status === 200) {
            localStorage.setItem('authToken', response.data.token)
            console.log(response.data)
            thunkApi.dispatch(login(response.data));
            return;
        }
        

        thunkApi.dispatch(showAlert({message: 'Usuario y/o Contraseña incorrectos', severity: 'error'}));
        thunkApi.dispatch(stoppingLogin())
    } catch (e) {
        switch(e.response.status) {
            case 404:
                thunkApi.dispatch(showAlert({message: 'Usuario y/o Contraseña incorrectos', severity: 'error'}));
                thunkApi.dispatch(stoppingLogin())
                break;
            case 400:
                thunkApi.dispatch(showAlert({message: 'Bad Request', severity: 'error'}));
                thunkApi.dispatch(stoppingLogin())
                break;
            case 500:
                thunkApi.dispatch(showAlert({message: 'Error interno del servidor', severity: 'error'}));
                thunkApi.dispatch(stoppingLogin())
                break;
            default:
                thunkApi.dispatch(showAlert({message: 'Error desconocido', severity: 'error'}));
                thunkApi.dispatch(stoppingLogin())
        }
    }
})

const setUserData = (state, action) => {

    return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        logging: false,
        error: ''
    }
}

const stopLogin =  (state, action) => {
    return {
        ...state,
        logging: false
    }
}

const LoginInit =  (state, action) => {
    return {
        ...state,
        logging: true
    }
}

export const  UserLogout = (state, action) => {
    
    localStorage.setItem('authToken', '')
    return {
        ...state,
        token: undefined,
        user: undefined,
        logging: false,
        error: ''
    }
}

const LoginReducer = (state, action) => {

    if (state === undefined) {
        return initialState;
    }

    switch (action.type) {
        case 'UserLogin':
            return setUserData(state, action);
        case 'UserLogout':
            return UserLogout(state, action);
        case 'LoginStop':
            return stopLogin(state, action);
        case 'LoginInit':
            return LoginInit(state, action);
        default:
            return state;
    }
}


export default LoginReducer

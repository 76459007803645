import { useState } from "react"
import isMobile from 'ismobilejs';
import { Box} from '@mui/material';
import './styles.scss'
import { params } from "../../config/parameters";



const Slider = (props) => {
    const [media, setMedia] = useState(props.media ?? []);
    const [index, setIndex] = useState(0);
    const [selectedMedia, setSelectedMedia] = useState(props.media ? props.media[0] : undefined);
    const mobile = isMobile(window.navigator.userAgent);

    const back = () => {
        let newIndex;

        if (index > 0) {
            newIndex = index - 1;
        } else {
            newIndex = media.length - 1;
        }

        setSelectedMedia(media[newIndex]);
        setIndex(newIndex);
    }

    const next = () => {
        let newIndex;
        
        if (index < (media.length - 1)) {
            newIndex = index + 1;
        } else {
            newIndex = 0;
        }

        setSelectedMedia(media[newIndex]);
        setIndex(newIndex);
    }
 
    let slide = '';
    let width = 1240;
    let height = 697.5;
    let leftBtn = '';
    let rightBtn = '';

    if (mobile.phone) {
        width = 400;
        height = 225;
    }

    if (mobile.tablet) {
        width = 800;
        height = 450;
    }

    if (media.length > 1) {
        leftBtn = <button className="slideControl left" onClick={back} style={{height: height}}> {"<"} </button>;
        rightBtn = <button className="slideControl right" onClick={next} style={{height: height}}> {">"} </button>;
    }


    if (media.length > 0 && selectedMedia !== undefined) {

        if ( selectedMedia.media.type == 5) {

            slide = <Box className="slideContainer" sx={{width: width, height: height}}>
                {leftBtn}
                <img src={params.apiBaseUrl + 'api/media/' + selectedMedia.media.id + '/download'} width={width} height={height}></img>
                {rightBtn}
             </Box>
        } else if (selectedMedia.media.type == 10) {
            
            slide = <Box className="slideContainer">
                {leftBtn}
                <video width={width} height={height}>
                    <source src={selectedMedia.media.baseurl + '/' + selectedMedia.media.file} type={selectedMedia.media.mime}></source>
                </video>
                {rightBtn}
            </Box>
        }

    }

    return (slide)
}

export default Slider;
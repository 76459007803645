import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { privRequest } from '../../api/PrivateRequest';
import { API_READY_STATUS, API_LOADING_STATUS } from '../../config/constants';
import { cleanAlert, showAlert } from '../alert/reducer';

const initialState = {
    list: [],
    selector: [],
    category:  {
        id: undefined,
        name: '',
        menu: false,
        parent: {
            id: undefined,
            name: '',
        }
    },
    model:  {
        id: undefined,
        name: '',
        menu: false,
        parent_id: undefined
    },
    apiState: API_READY_STATUS,
}

export const listCategories = createAsyncThunk('category/list', async (data, thunkApi) => {
    
    try {
        const response = await privRequest('backend/article-category', 'GET', data.params, thunkApi.getState().login.token);

        data.onsuccess(response.data)

    } catch (ex) {
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ocurrio un error al cargar categorias', severity: 'error'}));
    }

});

export const getCategory = createAsyncThunk('category/get', async (data, thunkApi) => {
    const response = await privRequest('backend/article-category' + data.id, 'GET', data, thunkApi.getState().login.token);
    return response;
});

export const loadModel = createAsyncThunk('category/load', async (data, thunkApi) => {
    const response = await privRequest('backend/article-category' + data.id, 'GET', data, thunkApi.getState().login.token);
    return response;
});

export const createCategory = createAsyncThunk('category/new', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/article-category', 'POST', data.model, thunkApi.getState().login.token);
        if (response.data.id) {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Categoria creada con exito', severity: 'success'}));
            data.onsuccess(response.data);
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al crear la categoria', severity: 'success'}));
        }
    } catch(e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
})

export const categoriesForSelector = createAsyncThunk('category/selector', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/article-category/selector/categories', 'GET', {}, thunkApi.getState().login.token);
        return response;
    } catch(e) {
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
})

const categorySlice = createSlice({
    name: 'category',
    initialState: initialState,
    reducers: {
        setData: (state, action) => {
            state.model[action.payload.field] = action.payload.value
        }
    },
    extraReducers: (builder) => {
        builder.addCase(listCategories.pending, (state, action) => {
            state.apiState = API_LOADING_STATUS;
        })
        builder.addCase(listCategories.fulfilled, (state, action) => {
            state.apiState = API_READY_STATUS;
        })
        builder.addCase(listCategories.rejected, (state, action) => {
            state.apiState = API_READY_STATUS;
            console.log('Error');
        })
        builder.addCase(getCategory.pending, (state, action) => {
            state.apiState = API_LOADING_STATUS;
        })
        builder.addCase(getCategory.fulfilled, (state, action) => {
            state.apiState = API_READY_STATUS;
        })
        builder.addCase(getCategory.rejected, (state, action) => {
            state.apiState = API_READY_STATUS;
            console.log('Error');
        })
        builder.addCase(loadModel.pending, (state, action) => {
            state.apiState = API_LOADING_STATUS;
        })
        builder.addCase(loadModel.fulfilled, (state, action) => {
            state.apiState = API_READY_STATUS;
            state.model = action.payload.data
        })
        builder.addCase(loadModel.rejected, (state, action) => {
            state.apiState = API_READY_STATUS;
            console.log('Error');
        })
        builder.addCase(createCategory.pending, (state, action) => {
            state.apiState = API_LOADING_STATUS;
        })
        builder.addCase(createCategory.rejected, (state, action) => {
            state.apiState = API_READY_STATUS;
        })
        builder.addCase(categoriesForSelector.fulfilled, (state, action) => {
            state.apiState = API_READY_STATUS;
            state.selector = action.payload.data
        })
        builder.addCase(categoriesForSelector.rejected, (state, action) => {
            state.apiState = API_READY_STATUS;
        })

       
    }
})

const categoryReducer = categorySlice.reducer;

export const {setData} = categorySlice.actions;

export default categoryReducer;
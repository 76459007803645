import React from "react";
import { Grid, Box, Typography, Divider } from '@mui/material';
import TopArticleBox from "../components/TopArticleBox/TopArticleBox";
import MainLayout from "../layouts/main";
import ColumnDerecha from "../components/columna-derecha/ColumnaDerecha";



const Home = () => {

    return (
        <MainLayout>
            <Grid container spacing={10}>
                <Grid item xs={12} md={8}>
                    <TopArticleBox></TopArticleBox>
                </Grid>
                <Grid item container xs={12} md={4}>
                    <ColumnDerecha></ColumnDerecha>
                </Grid>
            </Grid>
        </MainLayout>
    )
}

export default Home;
import { Box } from '@mui/system';
import React from 'react';
import './styles.scss'


const Panel = (props) => {

    return (
        <Box className={"panel " + props.className}>
            <Box className="panelHeader" style={props.headerStyles}>
                {props.title}
            </Box>
            <Box className="panelBody" style={props.bodyStyles}>
                {props.children}
            </Box>
        </Box>
    )
}

export default Panel;
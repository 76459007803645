import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import './App.scss';
import Router from './routes/Router';
import store from './store/store'
import { Provider } from 'react-redux';
import AlertManager from './components/alert/AlertManager';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'



const  App = (props) => {

  

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="App">
          <AuthProvider>
            <Router></Router>
          </AuthProvider>
        </div>
        <AlertManager></AlertManager>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;

import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar'
import { Box, List, ListItem, Button} from '@mui/material';
import { NavLink } from "react-router-dom";
import isMobile from 'ismobilejs';
import logo from '../../assets/logo_new.png'
import './style.scss'

const MenuBar = () => {

   const mobile = isMobile(window.navigator.userAgent);

   
   let menu = <List className="navigation">
               <ListItem className="ListItem">
                  <NavLink to="/">Inicio</NavLink>
               </ListItem>
               <ListItem className="ListItem">
                  <NavLink to="/articles">Noticias</NavLink>
               </ListItem>
               <ListItem className="ListItem">
                  <NavLink to="/eventos">Eventos</NavLink>
               </ListItem>
               <ListItem className="ListItem">
                  <NavLink to="/comision">Comisión</NavLink>
               </ListItem>
            </List>

   if (mobile.phone) {
      menu = '';
   }
    
    return (
       <AppBar className="AppBar" position="static">
                    <Box sx={{display: {md: 'flex'}}}>
                        <img src={logo} style={{height:'100px', width:'177px', marginRight: "100px", paddingLeft:"20px"}}></img>
                        {menu}
                        <Box className="adminBtnCon">
                           <Button className="adminBtn" href="/admin" variant="contained" color="error" target="_blank">Acceso Administradores</Button>
                        </Box>
                    </Box>
       </AppBar>
    )
}

export default MenuBar
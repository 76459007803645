import { FormControl, FormGroup,  Grid, InputLabel, MenuItem, OutlinedInput, Select, Button, Box } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import JoditEditor from "jodit-react";
import { useParams, useNavigate } from 'react-router';
import Admin from '../../../layouts/admin';
import Page from '../Page';
import { connect } from 'react-redux';
import Title from '../components/title/Title';
import { listCategories } from '../../../store/category/reducer';
import MediaUploader from '../../../components/media/MediaUploader';
import './styles.scss'
import { createArticle, updateArticle, loadModel } from '../../../store/article/reducer';
import CircularProgress from '@mui/material/CircularProgress';
import CategorySelector from '../components/category-selector/CategorySelector';

const ArticleForm = (props) => {

    const ARTICLE_PUBLISHED = 10;
    const ARTICLE_DRAFT     = 1


    const { id } = useParams();
    const navigate = useNavigate();
    const editor = useRef(null);
    const config = {
        readonly: false,
        height: 400
      };

    let [ title, setTitle ] = useState("Nuevo Artículo");
    let [ model,  setModel]  = useState({
        title: undefined,
        short_title: undefined,
        description: undefined,
        content: '',
        status: ARTICLE_DRAFT,
        category_id: undefined,
        articleMedia: []
    });
    let [loading, setLoading ] = useState(false);
    let [ categories, setCategories] = useState([])

    
    useEffect(() => {
        if (id) {
            setLoading(true);
            props.dispatch(loadModel({id: id, onSuccess: (response) => {
                setModel({
                    title: response.title,
                    short_title: response.short_title,
                    description: response.description,
                    content: response.content,
                    status: response.status,
                    category_id: response.category?.id,
                    articleMedia: response.articleMedia
                })
                setLoading(false);
                setTitle("Actualizar: " + response.title)
            }}))
        }
    },[id])

    /* useEffect(() => {
        props.dispatch(listCategories({param:{page: 0}, onsuccess: (response) => {
            setCategories(response.categories)
        }}));
    }, []) */

    

    const handleChangeCategory = (e) => {
        setModel({
            ...model,
            category_id: parseInt(e.target.value)
        });
    }

    const handleChangeStatus = (e) => {
        setModel({
            ...model,
            status: parseInt(e.target.value)
        });
    }

    const handleAfterUploadMedia = (media, title) => {
        let medias = model.articleMedia;

        medias.push({
            media_id: media.id,
            title: title,
            media: media
        });

        setModel({
            ...model,
            articleMedia: medias
        });
    }

    const handleRemoveMedia = (index) => {
        let medias = model.articleMedia;

        medias.splice(index, 1);

        setModel({
            ...model,
            articleMedia: medias
        });
    }

    const handleMediaChangeTitle = (index, title) => {
        let medias = model.articleMedia;

        medias[index].title = title;

        setModel({
            ...model,
            articleMedia: medias,
        })
    }

    const handleArticleSubmit = () => {
        let mod = model;
        mod.content = editor.current.value;
        if (id === undefined) {
            props.dispatch(createArticle({model: mod, onSuccess: (response) => {
                navigate('/admin/article/' + response.id)
            }})) 
        } else {
            props.dispatch(updateArticle({id: id, model: mod, onSuccess: (response) => {
                navigate('/admin/article/' + id)
            }}))
        }
    }

    const titleField = <FormControl fullWidth>
        <InputLabel variant="outlined">Título*</InputLabel>
        <OutlinedInput 
            id="title" 
            margin="dense" 
            defaultValue={model.title}
            onBlur={(e) => {
                setModel({
                    ...model,
                    title: e.target.value
                })
            }}
        ></OutlinedInput>
    </FormControl>

    const shortField = <FormControl fullWidth>
        <InputLabel>Título Corto</InputLabel>
        <OutlinedInput 
            id="short" 
            defaultValue={model.short_title}
            onBlur={(e) => {
                setModel({
                    ...model,
                    short_title: e.target.value
                })
            }}
        ></OutlinedInput>
    </FormControl>

    const descriptionField = <FormControl fullWidth>
        <InputLabel>Resumen*</InputLabel>
        <OutlinedInput 
            id="description" 
            defaultValue={model.description}
            onBlur={(e) => {
                setModel({
                    ...model,
                    description: e.target.value
                })
            }}
        ></OutlinedInput>
    </FormControl>

    
    const categoryField = <CategorySelector onChange={handleChangeCategory} value={model.category_id}></CategorySelector>
    

    const statusField =  <FormControl fullWidth >
        <InputLabel>Estado</InputLabel>
        <Select
            labelId="status-simple-select-label"
            id="status-simple-select"
            defaultValue={model.status}
            label="Estado"
            onChange={handleChangeStatus}
        >
            <MenuItem value={ARTICLE_DRAFT} default>Borrador</MenuItem>
            <MenuItem value={ARTICLE_PUBLISHED} >Publicado</MenuItem>
        </Select>
        </FormControl>

   
    const contentField = <FormGroup>
        <InputLabel>Contenido</InputLabel>
        <JoditEditor
        ref={editor}
        value={model.content}
        config={config}
        
      />
    </FormGroup>

    const submitButton = <Button variant="contained" color="success" onClick={handleArticleSubmit}>Guardar</Button>
    
    let bodyPage = <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>

    if (!loading) {

        bodyPage = <Grid container rowSpacing={3}>
                        <Grid item xs={6}>
                            <Grid container rowSpacing={3}>
                                <Grid item xs={12}>
                                    {titleField}
                                </Grid>
                                <Grid item xs={12}>
                                    {shortField}
                                </Grid>
                                <Grid item xs={12}>
                                    {descriptionField}
                                </Grid>
                                <Grid item xs={12}>
                                    {categoryField}
                                </Grid>
                                <Grid item xs={12}>
                                    {statusField}
                                </Grid>
                                <Grid item xs={12}>
                                    {contentField}
                                </Grid>
                                <Grid item xs={12}>
                                    {submitButton}
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <MediaUploader 
                                        media={model.articleMedia} 
                                        onUpload={handleAfterUploadMedia}
                                        onChangeTitle={handleMediaChangeTitle}
                                        deleteAction ={handleRemoveMedia}
                                    ></MediaUploader>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
    }

    return (
        <Admin>
            <Page>
                <Title>
                    {title}
                </Title>
                
                {bodyPage}
            </Page>
        </Admin>
    )
}


export default connect(state => ({articleData: state.article, login: state.login, categoryData: state.category}))(ArticleForm);
import React, { useEffect, useState } from "react"
import { Grid, Box, Typography, Divider, Pagination } from '@mui/material';
import ArticleItem from "./ArticleItem";
import MainLayout from '../../layouts/main';
import { connect } from "react-redux";
import { findArticles } from "../../store/article/reducer";
import ColumnDerecha from "../../components/columna-derecha/ColumnaDerecha";


const ArticleIndex = (props) => {

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        props.dispatch(findArticles({data:{query: {page: page}}, onsuccess: (response) => {
            setArticles(response.data);
            setCount(response.pagination.count)
            setTotal(response.pagination.totalPages)
            setLoading(false);
        }}))
    }, [page])

    const handlePagination = (event, value) => {
        setPage(value)
    }

    const items = articles.map((article) => {
        return <ArticleItem article={article} skeletonMode={loading}></ArticleItem>
    });


    return <MainLayout>
        <Grid container>
            <Grid item xs={12} md={8}>
                <Box className="articleIndexTitle">
                    <Typography variant="h4">Noticias</Typography>
                    <Divider sx={{border: "1.5px solid #000"}}></Divider>
                </Box>
                <Box>
                    <Grid container className="articleIndex">
                        {items}
                    </Grid>
                </Box>
                <Box className="paginationContainer">
                    <Pagination count={count} variant="outlined" shape="rounded" onChange={handlePagination} />
                </Box>
            </Grid>
            <Grid xs={12} md={4}>
                <ColumnDerecha></ColumnDerecha>
            </Grid>
        </Grid>
    </MainLayout>
}

export default connect(stste => ({}))(ArticleIndex)
import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { listWebBlockGroups, webBlockGroupsMenu } from "../../../../store/web-block/reducer";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './styles.scss'
import { UserLogout } from "../../../../store/login/reducer";
import { logout } from "../../../../store/login/actions";

const Menu = (props) => {


    const [webBlockGroups, setWebBlockGroups] = useState();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const doLogout = () => {
        props.dispatch(logout())
    }

    useEffect(() => {
        props.dispatch(webBlockGroupsMenu({onsuccess: (response) => {
            setWebBlockGroups(response)
        }}))        
    }, []);

    let menuItems = [
        <ListItemButton>
            <ListItemIcon></ListItemIcon>
            <NavLink to="/admin/dashboard">Inicio</NavLink>
        </ListItemButton>,
        <ListItemButton>
            <ListItemIcon></ListItemIcon>
            <NavLink to="/admin/article">Artículos</NavLink>
        </ListItemButton>,
        <ListItemButton>
            <ListItemIcon></ListItemIcon>
            <NavLink to="/admin/category">Categorias de Artículo</NavLink>
        </ListItemButton>,
        <ListItemButton>
            <ListItemIcon></ListItemIcon>
            <NavLink to="/admin/events">Eventos</NavLink>
        </ListItemButton>,
       <ListItemButton>
            <ListItemIcon></ListItemIcon>
            <NavLink to="/admin/comision">Comisión</NavLink>
        </ListItemButton>,
    ];

    let blocksLink = <ListItemButton onClick={handleClick}>
                        <ListItemIcon></ListItemIcon>
                        <NavLink to="/admin/web-blocks">Bloque Editables</NavLink>
                    </ListItemButton>;

    let listGroup = []
    let collapsable = '';

    if (webBlockGroups !== undefined && webBlockGroups.length > 0) {
        blocksLink =  <ListItemButton onClick={handleClick}>
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary="Bloques Editables"></ListItemText>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>;
        listGroup = webBlockGroups.map((group) => {
            if (group.slug === "eventos" || group.slug === "comision") {
                return undefined
            }
            
            return <ListItemButton sx={{ pl: 4, paddingLeft: "100px" }}>
                     <NavLink to={"/admin/web-blocks/" + group.slug}>{group.name}</NavLink>
                </ListItemButton>
        });

        collapsable = <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" sx={{textAlign: "center"}} >
                            {listGroup}
                        </List>
                    </Collapse>
    }

    menuItems.push(blocksLink);
    menuItems.push(collapsable);

    if (props.login.user?.roles.indexOf('ROLE_SUPERADMIN') !== -1 ) {
        menuItems = menuItems.concat([
            <ListItemButton>
                <ListItemIcon></ListItemIcon>
                <NavLink to="/admin/web-block-group">Grupo de Bloques Editables</NavLink>
            </ListItemButton>
        ])
    }

    menuItems = menuItems.concat([
        <ListItemButton>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Cerrar Sesion" onClick={doLogout}></ListItemText>
        </ListItemButton>
    ])


    return (
        <List
            className = "sidebar_menu"
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader" sx={{bgcolor:"#1F1F20", color:"white"}}>
                    Administrador Web - AGBA
                </ListSubheader>
            }
        >
            {menuItems}
        </List>
    )
}

export default connect(state =>({login: state.login}))(Menu)
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    open: false,
    message: '',
    severity: 'warning'
}

export const alertSlice = createSlice({
    name: 'alert',
    initialState: initialState,
    reducers: {
        showAlert: (state, action) => {
            state.message = action.payload.message;
            state.open    = true;
            state.severity= action.payload.severity
        },
        cleanAlert: (state, action) => {
            console.log('dispara');
            state.open    = false;
            state.message = '';
        }
    }
})

const alertReducer = alertSlice.reducer;

export const {showAlert, cleanAlert} = alertSlice.actions;

export default alertReducer;
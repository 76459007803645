import EventosBlock from "./EventosBlock";
import Facebook from "./Facebook";


const ColumnDerecha = (props) => {

    return (
        <>
           <EventosBlock></EventosBlock>,
           <Facebook></Facebook>
        </>
        
        
    )
}

export default ColumnDerecha;
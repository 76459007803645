import { Button, Stack } from "@mui/material";
import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { API_LOADING_STATUS } from "../../../config/constants";
import Admin from "../../../layouts/admin";
import { deleteArticle, listArticle } from "../../../store/article/reducer";
import DataTable from "../components/table/Table";
import Title from "../components/title/Title";
import Page from "../Page";

const Articles = (props) => {

    const STATUS_LABELS = {
        10: 'Publicado',
        1:  'Borrador'
    }
    const tableColumns = [
        {
            name: 'Título',
            value: 'title'
        },
        {
            name: 'Descripción',
            value: 'description'
        },
        {
            name: 'Estado',
            value: (row) => {
                return STATUS_LABELS[row.status]
            }
        },
        {
            name: 'Likes',
            value: 'likes'
        },

    ];
    const [articles, setArticles] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)

    const handlePagination = (event, page) => {
        setPage(page)
    }

    const deleteAction = (id) => {
        props.dispatch(deleteArticle({id: id, onsuccess: (response) => {
            window.location.reload();
        }}))
    }

    useEffect(() => {
        setLoading(true)
        props.dispatch(listArticle({data: {query:{page}}, onsuccess: (response) => {
            setArticles(response.data);
            setTotalPages(response.pagination.totalPages)
            setLoading(false)
        }}))        
    }, [page]);

    return (
        <Admin>
            <Page>
                <Title>
                    Artículos
                </Title>

                <Stack direction="row" spacing={2}>
                    <Link to="/admin/article/new" >
                        <Button variant="contained" color="success">
                            New Article
                        </Button>
                    </Link>
                </Stack>                


                <DataTable 
                    columns={tableColumns} 
                    data={articles} 
                    loading={loading} 
                    deleteAction={deleteAction}
                    handlePagination = {handlePagination}
                    totalPages = {totalPages}
                ></DataTable>
            </Page>
        </Admin>
    )
}


export default connect(state => ({article: state.article, login: state.login}))(Articles)
import React from "react";
import {Grid} from "@mui/material"
import Menu from "../pages/admin/components/menu/Menu";



const Admin = (props) => {

    return (
       <Grid container sx={{height: "100%"}}>
           <Grid item xs={2}>
                <Menu></Menu>
           </Grid>
           <Grid item xs={10} style={{paddingBottom: "25px"}}>
                {props.children}
           </Grid>
       </Grid>
    )
}

export default Admin
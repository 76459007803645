import axios from 'axios';
import { params } from '../config/parameters'

const privRequest = (endpoint, method, data, credential) => {
    let req;

    const instance = axios.create();

    instance.interceptors.response.use((response) => {
        return response
    }, (error) => {
        console.log(error.response.status)
        if (error.response.status === 401 || error.response.status === 403) {
            window.location.href= '/admin/login';
        }

        return Promise.reject(error)
    })

    switch (method) {
        case 'GET':
            req = instance.get(
                getUrl(endpoint, data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + credential,
                        'Access-Control-Allow-Origin': '*'
                    },
                    data: data,
                }
            );
            break;
        case 'POST':
            req = instance.post(
                getUrl(endpoint),
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + credential,
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );
            break;
        case 'PUT':
            req = instance.put(
                getUrl(endpoint),
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + credential,
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );
            break;
        case 'DELETE':
            req = instance.delete(
                getUrl(endpoint),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + credential,
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );
            break
    }

    

    return req;
}

const getUrl = (endpoint, data) => {
    let url =  params.apiBaseUrl + endpoint ;

    if (data && data.query) {
        const keys = Object.keys(data.query);

        url = url + '?';

        keys.forEach(key => {
            url = url + key + '=' + data.query[key] + '&'
        });

    }

    return url;
} 

export { privRequest }
import { Box } from '@mui/system';
import React from 'react';
import './scss/Page.scss'

const Page = (props) => {

    return (
        <Box className="page">
            {props.children}
        </Box>
    )
}

export default Page;
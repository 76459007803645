import { ThemeProvider } from "@mui/private-theming";
import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Portrait from "../components/Portrait/Portrait";

export default function MainLayout(props) {



    return (
        <div >
                <Header></Header>
                <Portrait></Portrait>
                <>
                    {props.children}
                </>
                <Footer></Footer>
        </div>
    );
}
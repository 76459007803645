import React, { useEffect, useRef, useState } from "react"
import Admin from "../../../layouts/admin"
import Title from "../components/title/Title"
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useParams, useNavigate } from "react-router";
import JoditEditor from "jodit-react";
import MediaUploader from '../../../components/media/MediaUploader';
import { createWebBlock, findBlockGroupBySlug, findWebBlockById, listWebBlockGroups, updateWebBlock } from "../../../store/web-block/reducer";
import { connect } from "react-redux";
import Page from "../Page";
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const WebBlockForm = (props) => {

    const {group_slug}            = useParams();
    const {id}                    = useParams();
    const [slugGroup, setSlugGroup ] = useState(group_slug);
    const [group, setGroup]    = useState(undefined);
    const [webBlockGroups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel]     = useState({
        name: undefined,
        resume: undefined,
        title: undefined,
        resume: undefined,
        title: undefined,
        description: undefined,
        content: undefined,
        start_date: undefined,
        end_date: undefined,
        web_block_group_id: undefined,
        medias: []
    })

    const editor = useRef(null);
    const config = {
        readonly: false,
        height: 400
      };
    const navigate = useNavigate()

    useEffect(() => {
        if (group_slug !== undefined) {
            props.dispatch(findBlockGroupBySlug({slug: group_slug, onsuccess: (response) => {
                setGroup(response);
                setModel({
                    ...model,
                    web_block_group_id: response.id
                })
                setLoading(false)
            }}))
        } else {

            if (slugGroup !== undefined) {
                props.dispatch(findBlockGroupBySlug({slug: slugGroup, onsuccess: (response) => {
                    setGroup(response);
                    setModel({
                        ...model,
                        web_block_group_id: response.id
                    })
                    setLoading(false)

                }}))
            } else {
                if (id === undefined) {

                    props.dispatch(listWebBlockGroups({onsuccess: (response) => {
                        setGroups(response)
                        setModel({
                            ...model,
                            web_block_group_id: response.id
                        })
                        setLoading(false)
                    }}))
                } else {
                    props.dispatch(findWebBlockById({id: id, onsuccess: (response) => {
                        setModel({
                            ...response,
                            web_block_group_id: response.webBlockGroup.id
                        })
                        
                        setGroup(response.webBlockGroup);
                        setLoading(false)
                    }}))
                }
            }
        }

    }, [slugGroup, id])


    let body = <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
               </Box>

    const handleNameOnBlur = (e) => {
        setModel({
            ...model,
            name: e.target.value
        })
    }

    const handleResumeOnBlur = (e) => {
        setModel({
            ...model,
            resume: e.target.value
        })
    }

    const handleTitleOnBlur = (e) => {
        setModel({
            ...model,
            title: e.target.value
        })
    }

    const handleDescriptionOnBlur = (e) => {
        setModel({
            ...model,
            description: e.target.value
        })
    }

    const onChangeStartDate = (value) => {
        console.log(value)
        setModel({
            ...model,
            start_date: value 
        })
    }

    const onChangeEndDate = (value) => {
        setModel({
            ...model,
            end_date: value 
        })
    }

    const handleAfterUploadMedia = (media, title) => {
        let medias = model.medias;

        medias.push({
            media_id: media.id,
            title: title,
            media: media
        });

        setModel({
            ...model,
            medias: medias
        });
    }

    const handleRemoveMedia = (index) => {
        let medias = model.medias;

        medias.splice(index, 1);

        setModel({
            ...model,
            medias: medias
        });
    }

    const handleMediaChangeTitle = (index, title) => {
        let medias = model.medias;

        medias[index].title = title;

        setModel({
            ...model,
            medias: medias,
        })
    }

    const handleGroupOnChange = (e) => {
        const [gid, gslug] = e.target.value.split('-');
        setModel({
            ...model,
            webBlockGroup: gid
        });

        setSlugGroup(gslug);
    }

    const formatDateToSave = (date) => {
        
        return date.$y + '-' + (date.$M + 1) + '-' + date.$D;
    }

    const handleWebBlockSubmit = () => {
        let mod = model;

        if (editor !== null && editor.current !== null) {
            mod.content = editor.current.value;
        }

        if (mod.start_date !== undefined &&  typeof(mod.start_date) === 'object' ) {
            
            mod.start_date = formatDateToSave(mod.start_date) 
        }

        if (mod.end_date !== undefined &&  typeof(mod.end_date) === 'object' ) {
            mod.end_date = formatDateToSave(mod.end_date)
        }

        mod.webBlockMedia = mod.medias;

        if (id === undefined) {
            props.dispatch(createWebBlock({model: model, onsuccess: (response) => {
                navigate('/admin/web-blocks')
            }})) 
        } else {
            props.dispatch(updateWebBlock({id: id, model: model, onsuccess: (response) => {
                navigate('/admin/web-blocks')
            }}))
        }
    }

    let pageTitle = 'Nuevo Bloque Editable';

    if (!loading) {
        const nameField = <FormControl fullWidth>
                            <InputLabel>Nombre</InputLabel>
                            <OutlinedInput defaultValue={model.name} onBlur={handleNameOnBlur}></OutlinedInput>
                        </FormControl>;
        const resumeField = <FormControl fullWidth>
                            <InputLabel>Resumen</InputLabel>
                            <OutlinedInput defaultValue={model.resume} onBlur={handleResumeOnBlur}></OutlinedInput>
                        </FormControl>;

        let titleField  = "";
        let descriptionField = "";
        let contentField = "";
        let startDateField = "";
        let endDateField = "";
        let groupField = "";
        let mediaField = "";

        const saveButton = <Button variant="contained" color="success" onClick={handleWebBlockSubmit}>Guardar</Button>

        if (group !== undefined || id !== undefined) {
            if (group.has_title) {
                titleField = <FormControl fullWidth>
                            <InputLabel>Título</InputLabel>
                            <OutlinedInput defaultValue={model.title} onBlur={handleTitleOnBlur}></OutlinedInput>
                        </FormControl>;
            }

            if (group.has_description) {
                descriptionField = <FormControl fullWidth>
                            <InputLabel>Descripcion</InputLabel>
                            <OutlinedInput defaultValue={model.description} onBlur={handleDescriptionOnBlur}></OutlinedInput>
                        </FormControl>;
            }

            if (group.has_content) {
                contentField = <FormControl fullWidth>
                            <JoditEditor
                                ref={editor}
                                value={model.content}
                                config={config}
                            />
                        </FormControl>;
            }

            if (group.has_date) {
                startDateField = <FormControl fullWidth>
                            <DatePicker 
                                label="Fecha de Inicio" 
                                value={dayjs(model.start_date)} 
                                onChange={onChangeStartDate} 
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="DD-MM-YYYY"
                            />
                        </FormControl>;

                endDateField = <FormControl fullWidth>
                            <DatePicker 
                                label="Fecha de Fin" 
                                value={dayjs(model.end_date)} 
                                onChange={onChangeEndDate} 
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="DD-MM-YYYY"
                            />
                        </FormControl>;
            }

            if (group.has_media) {
                mediaField = <Grid container>
                                <Grid item xs={12}>
                                    <MediaUploader 
                                        media={model.medias} 
                                        onUpload={handleAfterUploadMedia}
                                        onChangeTitle={handleMediaChangeTitle}
                                        deleteAction ={handleRemoveMedia}
                                    ></MediaUploader>
                                </Grid>
                            </Grid>
            }

            if (group !== undefined) {
                pageTitle = 'Nuevo ' + (props.modelName !== undefined ? props.modelName : group.name) 
            }

            if (id !== undefined) {
                pageTitle = 'Actualizar ' + model.name; 
            }
        } else {
            const options = webBlockGroups.map((group) => {
                return <MenuItem value={group.id + '-' + group.slug}>{group.name}</MenuItem>
            })
            groupField = <FormControl fullWidth>
                <InputLabel id="web-block-group-select-label">Grupo de Bloques</InputLabel>
                <Select
                    labelId="web-block-group-select-label"
                    id="web-block-group-select"
                    value={model.webBlockGroup ? model.webBlockGroup.id : undefined}
                    label="Grupo de Bloques"
                    onChange={handleGroupOnChange}
                >
                    {options}
                </Select>
            </FormControl>;
        }

        body = <Box sx={{ display: 'flex' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {nameField}
                        </Grid>
                        <Grid item xs={12}>
                            {resumeField}
                        </Grid>
                        <Grid item xs={12}>
                            {groupField}
                        </Grid>
                        <Grid item xs={12}>
                            {titleField}
                        </Grid>
                        <Grid item xs={12}>
                            {descriptionField}
                        </Grid>
                        <Grid item xs={12}>
                            {contentField}
                        </Grid>
                        <Grid item xs={12}>
                            {startDateField}
                        </Grid>
                        <Grid item xs={12}>
                            {endDateField}
                        </Grid>
                        <Grid item xs={12}>
                            {saveButton}
                        </Grid>
                    </Grid>
                    {mediaField}
                </Box>
    }

    return (
        <Admin>
            <Page>
                <Title>{pageTitle}</Title>

                {body}
            </Page>
        </Admin>
    )
}

export default connect(state => ({}))(WebBlockForm)
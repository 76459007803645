import React from 'react';


const MediaButton = (props) => {
    return (
        <button className="mediaButton" onClick={props.onClick}>
            <div className="btnMediaIcon">+</div>
            Agregar Archivo
        </button>
    )
}

export default MediaButton
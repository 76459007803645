import {Grid} from '@mui/material'
import logo from '../../assets/images/noimage.png'
import './styles.scss'

const Proximamente = (props) => {

    return (
        <Grid container>
            <Grid item xs={12}>
                <img src={logo} className="proximaImage"></img>
                <h2>Página en construcción</h2>
            </Grid>
        </Grid>
    )    
}

export default Proximamente;

export const params = {
    apiBaseUrl: process.env.NODE_ENV === 'production' ?  'https://api.arpgba.com/' : 'http://backapi.web.local/',
    marvelousSign: 'S0Y-3L-M45-P3RR0N-4QU1' 
}




/**export const params = {
    apiBaseUrl: 'https://api.arpgba.com/',
    marvelousSign: 'S0Y-3L-M45-P3RR0N-4QU1' 
}**/


import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Divider } from '@mui/material';
import { getLastFiveArticle } from '../../store/article/reducer';
import TopArticle from './TopArticle'
import TopArticleGrande from './TopArticleGrande';
import { connect } from 'react-redux';
import  './styles-article.scss'

const TopArticleBox = (props) => {

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.dispatch(getLastFiveArticle({onsuccess: (response) => {
            setArticles(response);
            setLoading(false);
        }}))
    }, []);
    
    let grilla = [];

    articles.forEach((article, index) => {
        if (index == 0) {
            grilla.push(<TopArticleGrande article={article} skeletonMode={loading}></TopArticleGrande>)
        } else {
            grilla.push(<TopArticle article={article} skeletonMode={loading}></TopArticle>)
        }
    })


    return (
        <Box className="topArticleBox">
            <Typography variant="h5">Ultimas Noticias</Typography>
            <Divider sx={{border: "1.5px solid #000"}}></Divider>

            <Grid container sx={{paddingTop: "20px"}}>
                {grilla}
            </Grid>
        </Box>
    )
}

export default connect(state => ({}))(TopArticleBox);
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router";


const RedirectManager = (props) => {

    const navigate = useNavigate();
    useEffect(()=> {
        navigate(props.navigator.url)
    }, [props.navigator.url])

    
    return ('');
}

export default connect(state => ({navigator: state.navigator}))(RedirectManager)
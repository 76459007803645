import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

const ButtonStack = (props) => {

    const items = props.buttons.map((button) => {
        if (button.type == 'link') {
            return (
                <Link to={button.to} >
                    <Button variant="contained" color={button.color}>
                        {button.label}
                    </Button>
                </Link>
            );
        } else {
            return (
                <Button variant={button.variant} color={button.color} onClick={button.action}>
                    {button.label}
                </Button>
            );
        }
    })

    return <Stack direction="row" spacing={2}>
        {items}
    </Stack>     
}

export default ButtonStack
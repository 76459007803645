import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Admin from '../../../layouts/admin';
import Title from '../components/title/Title';
import Page from '../Page';
import {API_LOADING_STATUS } from '../../../config/constants';
import DataTable from '../components/table/Table';
import { listCategories } from '../../../store/category/reducer';
import { Button, colors, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { listWebBlockGroups } from '../../../store/web-block/reducer';

const WebBlockGroups = (props) => {

    const tableColumns = [
        {
            name: 'Nombre',
            value: 'name'
        },
        {
            name: 'Tiene Título',
            value: (model) => {
                if (model.has_title) {
                    return "Si";
                } else {
                    return "No"
                }
            }
        },
        {
            name: 'Tiene Descripción',
            value: (model) => {
                if (model.has_description) {
                    return "Si";
                } else {
                    return "No"
                }
            }
        },
        {
            name: 'Tiene Contenido',
            value: (model) => {
                if (model.has_content) {
                    return "Si";
                } else {
                    return "No"
                }
            }
        },
        {
            name: 'Tiene Media',
            value: (model) => {
                if (model.has_media) {
                    return "Si";
                } else {
                    return "No"
                }
            }
        },
        {
            name: 'Tiene Fecha',
            value: (model) => {
                if (model.has_date) {
                    return "Si";
                } else {
                    return "No"
                }
            }
        },
        {
            name: 'Slug',
            value: 'slug'
        },
    ];

    const [ webBlockGroups, setWebBlockGroups ] = useState([])
    const [ page, setPage] = useState(1);

    const handlePagination = (event, page) => {
        setPage(page)
    }


    useEffect(() => {
        props.dispatch(listWebBlockGroups({query:{page: page}, onsuccess: (response) => {
            console.log(response)
            setWebBlockGroups(response)
        }}))        
    }, [page]);

    return (
        <Admin>
            <Page>
                <Title>
                    Grupo de Bloques Editables
                </Title>

                <Stack direction="row" spacing={2}>
                    <Link to="/admin/web-block-group/new" >
                        <Button variant="contained" color="success">
                            Nuevo Grupo
                        </Button>
                    </Link>
                </Stack>                


                <DataTable columns={tableColumns} data={webBlockGroups} loading={props.webBlock.apiState == API_LOADING_STATUS} handlePagination={handlePagination}></DataTable>

            </Page>
        </Admin>
    )
}

export default connect(state =>({login: state.login, webBlock: state.webBlock}))(WebBlockGroups)
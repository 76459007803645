import { Grid, Box} from '@mui/material'
import dayjs from 'dayjs';
import noimage from '../../assets/images/noimage.png'

const Evento = (props) => {

    const fechaDesde = dayjs(props.event.start_date * 1000).locale('es-mx').format('DD[/]MM[/]YYYY')
    const fechaHasta = dayjs(props.event.end_date * 1000).locale('es-mx').format('DD[/]MM[/]YYYY')

    let image = <img src={noimage} className="eventoImg"></img>

    if (props.event.webBlockMedia[0]) {
        image = <img className="eventoImg" src={props.event.webBlockMedia[0].media.baseurl +  props.event.webBlockMedia[0].media.file}></img>
    }

    return (
        <Grid item container xs={12} className="evento">
            <Grid xs={6}>
                {image}
            </Grid>
            <Grid xs={6}>
                <Box className="eventItem">
                    <h3>{props.event.title}</h3>
                    <h4>Del {fechaDesde} al {fechaHasta} - {props.event.description}</h4>
                </Box>
            </Grid>
        </Grid>
    )
}


export default Evento
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import React, { useState, } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate  } from "react-router";
import { API_LOADING_STATUS } from "../../../config/constants";
import Admin from "../../../layouts/admin";
import { createCategory, getCategory, listCategories, setData } from "../../../store/category/reducer";
import Title from "../components/title/Title";
import Page from "../Page";


const CategoryForm = (props) => {

    const { id }     = useParams();
    const navigate   = useNavigate()
    
    let [pageTitle, setPageTitle]  = useState("New Category");
    let [model, setModel]          = useState({name: null, parent_id: null});
    let [nameError, setNameError]  = useState(false);
    
    useEffect(() => {
        if (id !== undefined) {
            getCategory({id: id, token: props.login.token});
        }    
    }, [])

    if (props.categoryData.category.name !== '') {
        setPageTitle("Update Category: " + props.categoryData.category.name)
    }

    useEffect(() => {
        if (props.categoryData.list.lenght == 0) {
            listCategories(props.login.token);
        }
    }, [])

    const handleChangeName = (e) => {
        let mod = model;
        mod.name = e.target.value
        setModel(mod);
    }

    const isValid = () => {
        if (document.getElementById('name') && document.getElementById('name').value == '') {
            setNameError(true)
            return false;
        }

        return true;
    }

    const create = () => {
        if (isValid()) {
            props.dispatch(createCategory({
                model,
                onsuccess: (response) => {
                    if (response.id) {
                        navigate('/admin/category')
                    }
                }
            }));
        }
    }

    


    let nameInput =(
        <FormControl fullWidth >
            <InputLabel>Name *</InputLabel>
            <OutlinedInput id="name" defaultValue={props.categoryData.model.name} onChange={handleChangeName} ></OutlinedInput>
        </FormControl>
    )

    if (nameError) {
        nameInput =(
            <FormControl error fullWidth >
                <InputLabel htmlFor="name">Name *</InputLabel>
                <OutlinedInput id="name" defaultValue={props.categoryData.model.name} onChange={handleChangeName} aria-describedby="errorName"></OutlinedInput>
                <FormHelperText id="errorName">Campo obligatorio</FormHelperText>
            </FormControl>
        )
    }

    let submitBtn = (
        <FormGroup row={true}>
            <Button color={props.categoryData.category ? 'success' : 'primary'} variant="contained" onClick={create}>Save</Button>
        </FormGroup>
    );

    if (props.categoryData.apiState === API_LOADING_STATUS) {
        submitBtn = (
            <FormGroup row={true}>
                <Button color={props.categoryData.category ? 'success' : 'primary'} variant="contained" disabled>Save</Button>
            </FormGroup>
        );
    }


    return (
        <Admin>
            <Page>
                <Title>
                    {pageTitle}
                </Title>

                <Box>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <FormGroup>
                                {nameInput}
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            {submitBtn}
                        </Grid>
                    </Grid>
                </Box>
            </Page>
        </Admin>
    );

}

export default connect(state =>({login: state.login, categoryData: state.category}))(CategoryForm);
import axios from 'axios';
import { params } from '../config/parameters'

const pubRequest = (endpoint, method, data) => {
    let req;

    switch (method) {
        case 'GET':
            req = axios.get(
                getUrl(endpoint, data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                        //'X-MARVELOUS-SIGN': params.marvelousSign,
                    },
                    data: data
                }
            );
            break;
        case 'POST':
            req = axios.post(
                getUrl(endpoint),
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                        //'X-MARVELOUS-SIGN': params.marvelousSign,
                    },
                }
            );
            break;
        case 'PUT':
            req = axios.put(
                getUrl(endpoint),
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                        //'X-MARVELOUS-SIGN': params.marvelousSign,
                    },
                }
            );
            break;
        case 'DELETE':
            req = axios.delete(
                getUrl(endpoint),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                        //'X-MARVELOUS-SIGN': params.marvelousSign
                    },
                }
            );
            break
    }

    return req;
}

const getUrl = (endpoint, data) => {
    let url =  params.apiBaseUrl + endpoint;

    if (data && data.query) {
        const keys = Object.keys(data.query);

        url = url + '?';

        keys.forEach(key => {
            url = url + key + '=' + data.query[key] + '&'
        });

    }

    return url;
} 

export { pubRequest }
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { findBlockOrCreate } from "../../store/web-block/reducer"
import { FacebookProvider, Page } from 'react-facebook';


const Facebook = (props) => {

    const [ AppId, setAppId] = useState(undefined)
    const [ pageUrl, setPageUrl] = useState(undefined)
    const facebookBlock = {
        slug: 'facebook-app-id',
        block: {
            name: 'App ID de Facebook',
            resume: 'App ID para configurar el box de Facebook'
        },
        group: {
            name: 'Redes Sociales',
            has_description: true ,
            slug: 'redes-sociales'
        }
    }

    const facebookPageBlock = {
        slug: 'facebook-page-url',
        block: {
            name: 'Url Page de Facebook',
            resume: 'Url Page para configurar el box de Facebook'
        },
        group: {
            name: 'Redes Sociales',
            has_description: true ,
            slug: 'redes-sociales'
        }
    }

    useEffect(() => {
        props.dispatch(findBlockOrCreate({block: facebookBlock, onsuccess: (response) => {
            setAppId(response.description);
        }}))

        props.dispatch(findBlockOrCreate({block: facebookPageBlock, onsuccess: (response) => {
            setPageUrl(response.description);
        }}))
    }, [])

    let vista = '';

    if (AppId !== undefined && pageUrl !== undefined) {
        vista =  <FacebookProvider appId={AppId}>
                    <Page href={pageUrl} tabs="timeline" />
                </FacebookProvider>    
    }

    return (
        <div style={{marginBottom: "20px"}}>
            {vista}
        </div>
    )
}

export default connect(state => {})(Facebook);
import React, { useState, useEffect } from "react"
import Admin from "../../../layouts/admin"
import Title from "../components/title/Title"
import Page from "../Page"
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from "react-router";
import { loadModel } from "../../../store/article/reducer";
import ButtonStack from "../components/buttons-stack/ButtonStack";
import DetailView from "../components/detail/ViewDetail";
import { connect } from "react-redux";



const ArticleView = (props) => {

    const [loading, setLoading] = useState(true);
    const [ model, setModel ] = useState(undefined)
    const { id } = useParams()
    const buttons = [
        {
            type: 'link',
            to: 'update',
            label: 'Actualizar',
            color: 'primary',
            variant: 'contained'
        }
    ];
    const attributes = [
        {
            label: 'Título Corto',
            value: 'short_title'
        },
        {
            label: 'Descripción',
            value: 'description'
        },
        {
            label: 'Título Corto',
            value: 'short_title'
        },
        {
            label: 'Slug',
            value: 'slug'
        },
        {
            label: 'Categoria',
            value: (model) => {
                return model.category.name;
            }
        },
        {
            label: 'Fecha Creación',
            value: 'created_at'
        },
        {
            label: 'Fecha Actualización',
            value: 'updated_at'
        }
    ]

    useEffect(() => {
        props.dispatch(loadModel({id: id, onSuccess: (response) => {
            setModel(response);
            setLoading(false)
        }}))
    }, []);

    let pageContent = <Page>
        <CircularProgress />
    </Page>


    if (!loading) {
        pageContent = <Page>
                        <Title>
                            {model.title}
                        </Title>

                        <ButtonStack buttons={buttons}></ButtonStack>

                        <DetailView model={model} attributes={attributes}></DetailView>

                        <div dangerouslySetInnerHTML={{__html: model.content}}></div>
                        
                    </Page>
    }
    return (
        <Admin>
            {pageContent}
        </Admin>
    )
}

export default connect((state) => {}) (ArticleView)
import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { privRequest } from '../../api/PrivateRequest';
import { pubRequest } from '../../api/PublicRequest';
import { API_READY_STATUS, API_LOADING_STATUS } from '../../config/constants';
import { cleanAlert, showAlert } from '../alert/reducer';

const initialState = {
    webBlockList: [],
    webBlockGroupList: [],
    webBlockGroup: {
        id: undefined,
        name: undefined,
        slug: undefined,
        has_title: undefined,
        has_description: undefined,
        has_content: undefined,
        has_media: undefined,
        has_date: undefined,
    },
    webBlock:  {
        id: undefined,
        name: '',
        title: undefined,
        description: undefined,
        content: undefined,
        start_date: undefined,
        end_date: undefined,        
    },
    apiState: API_READY_STATUS,
}

export const listWebBlockGroups = createAsyncThunk('web-block-group/list', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/web-block-group', 'GET', data, thunkApi.getState().login.token);
        data.onsuccess(response.data.data);
        
    } catch (e) {
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ocurrio un error al listar información', severity: 'error'}));
    }

})

export const webBlockGroupsMenu = createAsyncThunk('web-block-group/menu', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/web-block-group/for-menu', 'GET', data, thunkApi.getState().login.token);
        data.onsuccess(response.data);
        
    } catch (e) {
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ocurrio un error al listar información', severity: 'error'}));
    }

})

export const listWebBlocks = createAsyncThunk('web-block/list', async (data, thunkApi) => {
    try {

        const response = await privRequest('backend/web-block', 'GET', data, thunkApi.getState().login.token);

        data.onsuccess(response.data);
        
    } catch (e) {
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ocurrio un error al listar información', severity: 'error'}));
    }
})

export const findBlockGroupById = createAsyncThunk('web-block-group/byId', async (data, thunkApi) => {

    try {
        const response = await privRequest('backend/web-block-group/' + data.id, 'GET', {}, thunkApi.getState().login.token);

        if (response.data.id) {
            data.onsuccess(response.data);
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al buscar Grupo', severity: 'error'}));
        }
    } catch (e) {
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ocurrio un error al buscar Grupo', severity: 'error'}));
    }
})

export const findBlockGroupBySlug = createAsyncThunk('web-block-group/bySlug', async (data, thunkApi) => {

    try {
        const response = await privRequest('backend/web-block-group/' + data.slug, 'GET', {}, thunkApi.getState().login.token);

        if (response.data.id) {
            data.onsuccess(response.data);
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al buscar Grupo', severity: 'error'}));
        }
    } catch (e) {
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ocurrio un error al buscar Grupo', severity: 'error'}));
    }
})



export const createWebBlockGroup = createAsyncThunk('web-block-group/create', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/web-block-group', 'POST', data.model, thunkApi.getState().login.token);

        if (response.status === 200) {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Grupo creada con exito', severity: 'success'}));
            data.onsuccess(response.data);
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al crear el Grupo', severity: 'error'}));
        }
    } catch (e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
})

export const updateWebBlockGroup = createAsyncThunk('web-block-group/update', async (data, thunkApi) => {
    try {
        const response = await privRequest('block/web-block-group/' + data.id, 'PUT', data.model, thunkApi.getState().login.token);

        if (response.data.status == 'ok') {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Grupo actualizado con exito', severity: 'success'}));
            data.onsuccess(response.data);
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al actualizar el Grupo', severity: 'error'}));
        }
    } catch (e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
})

export const findWebBlockById = createAsyncThunk('web-block/byId', async (data, thunkApi) => {

    try {
        const response = await privRequest('backend/web-block/' + data.id, 'GET', {}, thunkApi.getState().login.token);

        if (response.status === 200) {
            data.onsuccess(response.data);
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al buscar Bloque', severity: 'error'}));
        }
    } catch (e) {
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ocurrio un error al buscar Bloque', severity: 'error'}));
    }
})

export const createWebBlock = createAsyncThunk('web-block/create', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/web-block', 'POST', data.model, thunkApi.getState().login.token);

        if (response.data.status) {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Bloque creada con exito', severity: 'success'}));
            data.onsuccess(response.data);
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al crear el Bloque', severity: 'error'}));
        }
    } catch (e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
})

export const updateWebBlock = createAsyncThunk('web-block/update', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/web-block/' + data.id, 'PUT', data.model, thunkApi.getState().login.token);

        if (response.status === 200) {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Bloque actualizado con exito', severity: 'success'}));
            data.onsuccess(response.data);
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al actualizar el Bloque', severity: 'error'}));
        }
    } catch (e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
})


export const findBlockOrCreate = createAsyncThunk('block/find-or-create', async (data, thunkApi) => {
    try {
        const response = await pubRequest('api/web-block/get-or-create', 'POST', data.block);

        console.log(response)
        if (response.status === 200) {
            data.onsuccess(response.data);
        } else {
            data.onsuccess(undefined);
        }

    } catch (e) {
        data.onsuccess(undefined);
    }
})

export const nextEvents = createAsyncThunk('next-events', async (data, thunkApi) => {

    try {
        const response = await pubRequest('api/events/next', 'GET');

        console.log(response)
        if (response.status == 200) {
            data.onsuccess(response.data);
        } else {
            data.onsuccess([]);
        }

    } catch (e) {
        data.onsuccess([]);
    }
});

export const allEvents = createAsyncThunk('all-events', async (data, thunkApi) => {

    try {
        const response = await pubRequest('api/events', 'GET', data);

        console.log(response)
        if (response.data == 200) {
            data.onsuccess(response.data);
        } else {
            data.onsuccess([]);
        }

    } catch (e) {
        data.onsuccess([]);
    }
});



const webBlockSlice = createSlice({
    name: 'webBlock',
    initialState: initialState,
    reducers: {
        setDataWebBlock: (state, action) => {
            state.webBlock[action.payload.field] = action.payload.value
        }
    },
    
})


const webBlockReducer = webBlockSlice.reducer;

export const {setDataWebBlock} = webBlockSlice.actions;

export default webBlockReducer;
import { Box, Button, Container, TextField, useMediaQuery, useTheme } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useMemo, useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import './scss/Login.scss'
import { loginUser } from "../../store/login/reducer";



const LoginForm = (props) => {
   
    const [username, setUsername] = useState(undefined);
    const [password, setPassword] = useState(undefined);
   

    const loginAction = () => {
        props.dispatch(loginUser({username: username, password: password}));
    }

    useEffect(() => {
        if (props.login.logging == true) {
            console.log('logueando');
        } 
    }, [props.login.logging]);

    

    return (
        <Box component="form" sx= {
            {
                width: "80%",
                margin: "50px auto"
            }
        }>
            <div style={{marginTop: "10px"}}>
                <TextField id="username" label="Usuario" variant="filled" fullWidth  onChange={(e) => setUsername(e.target.value)}/>
            </div>
            <div style={{marginTop: "10px"}}>
                <TextField id="password" label="Contraseña" variant="filled" type="password" fullWidth  onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div style={{marginTop: "10px"}}>
                <LoadingButton loading={props.login.logging === true} variant="contained" color="primary" onClick={loginAction}> Iniciar Sesión</LoadingButton>
            </div>
        </Box>
    )
}


const Login = (props) => {
    const theme = useTheme();
    const QrMatches = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate                = useNavigate();

    const { dispatch } = props

        
    let boxWidth = "50%";

    if (QrMatches) {
        boxWidth = "90%"
    }

    useEffect(() => {
        if(props.login.user !== undefined) {
            console.log('user cambio')
            navigate("/admin/dashboard", { replace: false})
        }
    }, [props.login.user])
    
    return (
        <Container sx={{
            width: "100%",
            height: "100vh",
            margin: "0px",
            backgroundColor: "#bebebe",
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center"
        }} maxWidth={false} disableGutters={true}>
                <Box sx={{
                    width: boxWidth,
                    height: "400px",
                    backgroundColor: "white",
                    border: "0.5px solid cccecf",
                    display: "flex",
                    flexDirection:"column",
                }}>
                    <div className="loginBoxTitle">
                        <h4>Administrador Web</h4>
                    </div>
                    <LoginForm login={props.login}  dispatch={dispatch}></LoginForm>
                    
                </Box>
        </Container>
    )
}

export default connect(state => ({login: state.login}))(Login);
import React from 'react';
import Slide from '@mui/material/Slide';
import { cleanAlert } from '../../store/alert/reducer';
import { connect, useDispatch } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';

const AlertManager = (props) => {

    const dispatch = useDispatch();

    return (
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={props.alert.open}
            TransitionComponent={Slide}
            onClose={() => {dispatch(cleanAlert())}}
            key={Slide.name}
        >
            <Alert onClose={() => {dispatch(cleanAlert())}}  severity={props.alert.severity} sx={{ width: '100%' }}>
                {props.alert.message}
            </Alert>
        </Snackbar>
    )
}

export default connect(state =>({alert: state.alert})) (AlertManager);
import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import Admin from '../../../layouts/admin';
import Title from '../components/title/Title';
import Page from '../Page';
import { createWebBlockGroup, findBlockGroupById, updateWebBlockGroup } from '../../../store/web-block/reducer';
import { useParams, useNavigate  } from "react-router";
import CircularProgress from '@mui/material/CircularProgress';




const WebBlockGroupsForm = (props) => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle ] = useState('Nuevo Grupo de Bloques Editable')

    const [model, setModel ] = useState({
        name: undefined,
        has_title: false,
        has_description: false,
        has_content: false,
        has_media: false,
        has_date: false,
    });
    const [loading, setLoading ] = useState(false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            props.dispatch(findBlockGroupById({id, onsuccess: (response) => {
                setTitle('Actualizar Grupo: ' + response.name)
                setModel(response);
                setLoading(false);
            }}))
        }
    }, [id])

    const handleNameOnBlur = (e) => {
        setModel({
            ...model,
            name: e.target.value
        })
    }

    const handleHasTitleChange = () => {
        setModel({
            ...model,
            has_title: !model.has_title
        })
    }

    const handleHasDescriptionChange = () => {
        setModel({
            ...model,
            has_description: !model.has_description
        })
    }

    const handleHasContentChange = () => {
        setModel({
            ...model,
            has_content: !model.has_content
        })
    }

    const handleHasDateChange = () => {
        setModel({
            ...model,
            has_date: !model.has_date
        })
    }

    const handleHasMediaChange = () => {
        setModel({
            ...model,
            has_media: !model.has_media
        })
    }

    const saveGroup = () => {
        if (id === undefined) {
            props.dispatch(createWebBlockGroup({model, onsuccess: (response) => {
                if (response.data.id) {
                    navigate('/admin/web-block-group');
                }
            }}))
        } else {
            props.dispatch(updateWebBlockGroup({id, model, onsuccess: (response) => {
                navigate('/admin/web-block-group');
            }}))
        }
    }

    const nameField = <FormControl fullWidth>
        <InputLabel>Nombre</InputLabel>
        <OutlinedInput defaultValue={model.name} onBlur={handleNameOnBlur}></OutlinedInput>
    </FormControl>;

    const hasTitleField = <FormGroup fullWidth>
        <FormControlLabel control={<Checkbox defaultChecked={model.has_title} onChange={handleHasTitleChange}></Checkbox>} label={"Tiene Título"}></FormControlLabel>
    </FormGroup>

    const hasDescriptionField = <FormGroup fullWidth>
        <FormControlLabel control={<Checkbox defaultChecked={model.has_description} onChange={handleHasDescriptionChange}></Checkbox>} label={"Tiene Descripción"}></FormControlLabel>
    </FormGroup>

    const hasContentField = <FormGroup fullWidth>
        <FormControlLabel control={<Checkbox defaultChecked={model.has_content} onChange={handleHasContentChange}></Checkbox>} label={"Tiene Contenido"}></FormControlLabel>
    </FormGroup>

    const hasDateField = <FormGroup fullWidth>
        <FormControlLabel control={<Checkbox defaultChecked={model.has_date} onChange={handleHasDateChange}></Checkbox>} label={"Tiene Fecha"}></FormControlLabel>
    </FormGroup>

    const hasMediaField = <FormGroup fullWidth>
        <FormControlLabel control={<Checkbox defaultChecked={model.has_media} onChange={handleHasMediaChange}></Checkbox>} label={"Tiene Media"}></FormControlLabel>
    </FormGroup>

    const submitBtn = <Button color="success" variant="contained" onClick={saveGroup}>Guardar</Button>

    let bodyPage = <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>

    if (!loading) {
        bodyPage = <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {nameField}
                            </Grid>
                            <Grid item xs={12}>
                                {hasTitleField}
                            </Grid>
                            <Grid item xs={12}>
                                {hasDescriptionField}
                            </Grid>
                            <Grid item xs={12}>
                                {hasContentField}
                            </Grid>
                            <Grid item xs={12}>
                                {hasDateField}
                            </Grid>
                            <Grid item xs={12}>
                                {hasMediaField}
                            </Grid>
                            <Grid item xs={12}>
                                {submitBtn}
                            </Grid>

                        </Grid>
                    </Box>
    }

    return (
        <Admin>
            <Page>
                <Title>{title}</Title>
                
                {bodyPage}    
            </Page>
        </Admin>
    )
    
}

export default connect(state => ({webBlock: state.webBlock}))(WebBlockGroupsForm)
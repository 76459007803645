import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Admin from "../../../layouts/admin";
import { listWebBlocks } from "../../../store/web-block/reducer";
import DataTable from "../components/table/Table";
import Title from "../components/title/Title";
import Page from "../Page";
import { Button, colors, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';


const WebBlocks = (props) => {

    const  group_slug         = props.group_slug
    const { group_slug_param } = useParams()
    const [ group, setGroup ] = useState(undefined)
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)

    const handlePagination = (event, page) => {
        setPage(page)
    }

    const columns = [
        {
            name: "Nombre",
            value: "name"
        },
        {
            name: "Resumen",
            value: "resume"
        }
    ]

    useEffect(() => {
        let data = {
            onsuccess: (response) => {
                setModels(response.data)
                setGroup(response.group)
                setTotalPages (response.pagination.totalPage)
                setLoading(false)
            }
        }

        if (group_slug != undefined) {
            data.query = {group: group_slug, page: page}
        } else if (group_slug_param != undefined) {
            data.query = {group: group_slug_param, page: page}
        } else {
            data.query = {page: page};
        }

       

        props.dispatch(listWebBlocks(data))

    }, [group_slug, group_slug_param, page]);

    let title = "Bloques Editables";
    let labelNuevo = "Nuevo Bloque";


    if (group) {
        title = group.name;
    }

    if (props.model) {
        labelNuevo = "Nuevo " + props.model
    }
    
    let newUrl = "/admin/web-blocks/new";

    if (group_slug !== undefined) {
        newUrl = "/admin/web-blocks/new/" + group_slug;
    }

    if (group_slug_param !== undefined) {
        newUrl = "/admin/web-blocks/new/" + group_slug_param;
    }

    return (
        <Admin>
            <Page>
                <Title>{title}</Title>

                <Stack direction="row" spacing={2}>
                    <Link to={newUrl} >
                        <Button variant="contained" color="success">
                           {labelNuevo}
                        </Button>
                    </Link>
                </Stack>     

                <DataTable 
                    loading={loading} 
                    data={models} 
                    columns={columns} 
                    endpoint={'/admin/web-blocks'}
                    handlePagination = {handlePagination}
                    totalPages = {totalPages}    
                ></DataTable>
            </Page>
        </Admin>
    );
}

export default connect((state => ({})))(WebBlocks)
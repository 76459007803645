import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Box, Pagination } from '@mui/material';
import React, {useState} from 'react';
import { useNavigate } from 'react-router'
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import './styles.scss'



const ActionCell = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = anchorEl;
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    let actions = [];

    if (props.defaultActions && props.defaultActions !== false) {
        actions = props.defaultActions.map((action) => {

            if (action.id === 'delete' && !props.showDelete) {
                return;
            } 

            return <MenuItem onClick={() => { action.action(props.row, props.endpoint)}}>
                        <ListItemIcon>
                            {action.icon}
                        </ListItemIcon>
                        <ListItemText>{action.label}</ListItemText>
                    </MenuItem>
        })
    }

    return <TableCell>
                <Button
                    id={"action-button-" + props.row.id}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreVertIcon></MoreVertIcon>
                </Button>
                <Menu
                    id={"action-menu-" + props.row.id}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': "action-button-" + props.row.id,
                    }}
                >
                    {actions}
                </Menu>
            </TableCell>
}



const DataTable = (props) => {

    const navigate = useNavigate();
    const defaultActions = [
        {
            id: 'view',
            label: 'Ver',
            icon: <VisibilityIcon/>,
            action: (row) => {
                navigate((row.id).toString())
            }
        },
        {
            id: 'update',
            label: 'Actualizar',
            icon: <EditIcon/>,
            action: (row, endpoint) => {
                if (endpoint) {
                    navigate(endpoint + '/' + row.id + '/update')
                } else {
                    navigate(row.id + '/update')
                }
            }
        },
        {
            id: 'delete',
            label: 'Eliminar',
            icon: <DeleteIcon/>,
            action: (row) => {
               if (props.deleteAction) {
                props.deleteAction(row.id);
               }
            }
        }
    ]

    const columns = props.columns.map((column) => {
        return <TableCell>{column.name}</TableCell>
    })

    columns.push(<TableCell></TableCell>)
    let rows = [
        <TableRow><TableCell colSpan={props.columns.length}>No data found</TableCell></TableRow>
    ]
     
    if (props.loading) {
        rows = [
            <TableRow><TableCell colSpan={props.columns.length}><CircularProgress /></TableCell></TableRow>
        ]
    }

    if (props.data.length > 0) {
        rows = props.data.map((row) => {
            let cells = props.columns.map((column) => {
                if (typeof column.value  === 'function') {
                    return <TableCell>{column.value(row)}</TableCell>                    
                } else {
                    return <TableCell>{row[column.value]}</TableCell>
                }
            });

            

            let actionCell = <ActionCell defaultActions={defaultActions} row={row} showDelete={props.deleteAction !== undefined} endpoint={props.endpoint}></ActionCell>

            cells.push(actionCell);
    
            return <TableRow>{cells}</TableRow>
        })
    }

    return (
        <TableContainer className="tableContainer">
            <Table>
                <TableHead>
                    <TableRow>{columns}</TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
            <Box className="paginationContainer">
                <Pagination count={props.totalPages} variant="outlined" shape="rounded" onChange={props.handlePagination} />
            </Box>
        </TableContainer>
    );
}

export default DataTable;
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect } from "react"
import { connect } from "react-redux"
import { categoriesForSelector } from "../../../../store/category/reducer"


const CategorySelector = (props) => {

    const items = props.categories?.map((category) => {
        return <MenuItem value={category.id}>{category.name}</MenuItem>
    })

    useEffect(() => {
        if (props.categories?.length === 0) {
            props.dispatch(categoriesForSelector())
        }
    }, [props.categories])

    return (
        <FormControl fullWidth>
            <InputLabel id="category-label">Categoria</InputLabel>
            <Select
                labelId="category-label"
                id="category-selector"
                defaultValue={props.value}
                onChange={props.onChange}
            >
                {items}
            </Select>
        </FormControl>
    )
}

export default  connect((state, ownProps) => ({...ownProps, categories: state.category.selector}))(CategorySelector);
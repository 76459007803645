import React from "react"
import { Grid, Box, Skeleton } from '@mui/material';
import noimage from "../../assets/images/noimage.png";
import { Link } from "react-router-dom";
import { params } from "../../config/parameters";

const ArticleItem = (props) => {

    let image = <img src={noimage} className="articleImage"></img>

    if (props.article && props.article.articleMedia.length > 0) {
        const media = props.article.articleMedia[0].media;
        const src = params.apiBaseUrl + 'api/media/' + media.id + '/download'
        image = <img src={src} className="articleImage"></img>
    }

    let article = <Grid item xs={12} lg={5} >
                        <Skeleton variant="rectangular" animation="wave"></Skeleton>
                    </Grid>

    if (!props.skeletonMode && props.article) {
        article = <Grid item xs={12} lg={5} className="articleItem">
                        {image}
                        <Box className="articleTitle">
                            <Link to={"/articles/" + props.article.slug}><h2 sx={{marginTop:"10px"}}>{props.article.short_title}</h2></Link>
                        </Box>
                    </Grid>
    }


    return article;
}

export default ArticleItem


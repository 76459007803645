import ColumnDerecha from "../../components/columna-derecha/ColumnaDerecha";
import MainLayout from "../../layouts/main";
import { Grid, Box, Typography, Divider } from '@mui/material'
import Proximamente from "../../components/proximamente/Proximamente";
import './styles.scss'

const ArchivosIndex = (props) => {


    return (
        <MainLayout>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Box className="archivosIndexTitle">
                        <Typography variant="h4">Archivos</Typography>
                        <Divider sx={{border: "1.5px solid #000"}}></Divider>
                    </Box>
                    <Proximamente></Proximamente>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ColumnDerecha></ColumnDerecha>
                </Grid>
            </Grid>
        </MainLayout>
    )
}

export default ArchivosIndex;
import { Avatar, Button, Dialog, DialogActions, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import TheatersIcon from '@mui/icons-material/Theaters';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const MediaTypeSelector = (props) => {

    const selectImageType = () => {
        props.onSelectType(5);
    }

    const selectVideoType = () => {
        props.onSelectType(10);
    }

    const selectDocumentType = () => {
        props.onSelectType(15);
    }

    const mediaTypes = [
        <ListItem button onClick={selectImageType}>
            <ListItemAvatar  >
                <Avatar sx={{bgcolor: "#cccecf"}}>
                    <AddPhotoAlternateIcon></AddPhotoAlternateIcon>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Imagen'}></ListItemText>
        </ListItem>,
        <ListItem button onClick={selectVideoType}>
            <ListItemAvatar>
                <Avatar sx={{bgcolor: "#cccecf"}}>
                    <TheatersIcon></TheatersIcon>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Video'}></ListItemText>
        </ListItem>,
        <ListItem button onClick={selectDocumentType}>
            <ListItemAvatar>
                <Avatar sx={{bgcolor: "#cccecf"}}>
                    <PictureAsPdfIcon></PictureAsPdfIcon>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Documento'}></ListItemText>
        </ListItem>
    ];


    return (
        <Dialog onClose={props.onClose} open={props.open} fullWidth>
            <DialogTitle>Agregar</DialogTitle>
            <List>
                {mediaTypes}
            </List>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default MediaTypeSelector;
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { articleBySlug } from '../../store/article/reducer';
import { Box, Grid } from '@mui/material';
import MainLayout from '../../layouts/main';
import './styles.scss'
import Slider from '../../components/slider/Slider';
import dayjs from 'dayjs';

const Article = (props) => {

    const [loading, setLoading] = useState(true);
    const [article, setArticle] = useState(undefined);
    const {slug} = useParams('slug');
    
    useEffect(() => {
        props.dispatch(articleBySlug({slug: slug, onsuccess: (response) => {
            setLoading(false);
            setArticle(response);
        }}))
    }, [slug])

    let body = <Box>
        
    </Box>

    if (!loading) {
        const datestring = article.createdAt;
        body = <Grid container className="article">
                    <Grid item xs={12} md={8}>
                        <Box className='articleDate'>
                            <small>{datestring + ' - ' + article.category.name}</small>
                        </Box>
                        <Box className="articleTitleContainer">
                            <h1 className='articleTitle'>
                                {article.title}
                            </h1>
                            <p className='articleResume'>{article.description}</p>
                        </Box>

                        <Slider media={article.articleMedia}></Slider>
                        <Box className="articleContent">
                            <div dangerouslySetInnerHTML={{__html: article.content}}></div>
                        </Box>
                    </Grid>
                </Grid>
    }

    return (
        <MainLayout>
            {body}
        </MainLayout>
    );
}

export default connect(state => ({}))(Article)
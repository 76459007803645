import ColumnDerecha from "../../components/columna-derecha/ColumnaDerecha";
import MainLayout from "../../layouts/main";
import { Grid, Typography, Box, Divider } from '@mui/material'
import { useEffect, useState } from "react";
import Proximamente from "../../components/proximamente/Proximamente";
import './styles.scss'

const AfiliadosIndex = (props) => {

    const [afiliados, setAfiliados] = useState([]);

    useEffect(() => {
        
        props.dispatch(findBlockOrCreate({block: presidenteBlock, onsuccess: (response) => {
            setPresidente(response);
        }}))
       
    }, [])

    return (
        <MainLayout>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Box className="afiliadosIndexTitle">
                        <Typography variant="h4">Afiliados</Typography>
                        <Divider sx={{border: "1.5px solid #000"}}></Divider>
                    </Box>
                    <Proximamente></Proximamente>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ColumnDerecha></ColumnDerecha>
                </Grid>
            </Grid>
        </MainLayout>
    )
}

export default AfiliadosIndex;
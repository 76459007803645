import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { privRequest } from '../../api/PrivateRequest';
import { pubRequest } from '../../api/PublicRequest';
import { API_READY_STATUS, API_LOADING_STATUS } from '../../config/constants';
import { cleanAlert, showAlert } from '../alert/reducer';
import { redirectTo } from '../navigator/reducer';

const initialState = {
    list: [],
    article: {
        id: undefined,
        title: undefined,
        short_title: undefined,
        description: undefined,
        content: undefined,
        category: undefined,
        media: undefined,
        created_at: undefined,
        updated_at: undefined,
        slug: undefined
    },
    model: {
        id: undefined,
        title: undefined,
        short_title: undefined,
        description: undefined,
        content: undefined,
        category: undefined,
        media: undefined,
        created_at: undefined,
        updated_at: undefined,
        slug: undefined
    },
    apiState: API_READY_STATUS,
}

export const listArticle = createAsyncThunk('article/list', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/article', 'GET', data.data, thunkApi.getState().login.token);

        data.onsuccess(response.data)
        
    } catch (ex) {
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
});

export const findArticles = createAsyncThunk('article/find', async (data, thunkApi) => {
    try {
        const response = await pubRequest('api/article', 'GET', data.data);

        data.onsuccess(response.data)
    }catch {
        data.onsuccess(response.data);
    }
})

export const loadModel = createAsyncThunk('article/load', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/article/' + data.id, 'GET', {}, thunkApi.getState().login.token);
        if (response.data.id) {
            thunkApi.dispatch(cleanAlert());
            data.onSuccess(response.data)
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al cargar el Artículo', severity: 'error'}));
        }
    } catch(e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }

});

export const articleBySlug = createAsyncThunk('article/by-slug', async (data, thunkApi) => {
    try {
        const response = await pubRequest('api/article/' + data.slug, 'GET', {});
        if (response.status === 200) {
            thunkApi.dispatch(cleanAlert());
            data.onsuccess(response.data)
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al cargar el Artículo', severity: 'error'}));
        }
    } catch(e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }

});

export const createArticle = createAsyncThunk('article/new', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/article', 'POST', data.model, thunkApi.getState().login.token);
        if (response.data.id) {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Artículo creado con éxito', severity: 'success'}));
            data.onSuccess(response.data)
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al crear el Artículo', severity: 'success'}));
        }
    } catch(e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
})

export const updateArticle = createAsyncThunk('article/update', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/article/' + data.id, 'PUT', data.model, thunkApi.getState().login.token);
        if (response.data.status === 'ok') {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Artículo actualizado con éxito', severity: 'success'}));
            data.onSuccess(response.data)
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al actualizar el Artículo', severity: 'error'}));
            return thunkApi.rejectWithValue()
        }
    } catch(e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
})

export const deleteArticle = createAsyncThunk('article/delete', async (data, thunkApi) => {
    try {
        const response = await privRequest('backend/article/article/' + data.id, 'DELETE', {}, thunkApi.getState().login.token);
        if (response.data.status === 'ok') {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Artículo eliminado con éxito', severity: 'success'}));
            data.onSuccess(response.data)
        } else {
            thunkApi.dispatch(cleanAlert());
            thunkApi.dispatch(showAlert({message: 'Ocurrio un error al eliminar el Artículo', severity: 'error'}));
            return thunkApi.rejectWithValue()
        }
    } catch(e) {
        console.log(e)
        thunkApi.dispatch(cleanAlert());
        thunkApi.dispatch(showAlert({message: 'Ha ocurrido un error: ' + e.response.data.error, severity: 'error'}));
        return thunkApi.rejectWithValue()
    }
})

export const getLastFiveArticle = createAsyncThunk('article/last-five', async (data, thunkApi) => {
    try {
        const response = await pubRequest('api/article/last-five', 'GET', {});

        if (response.status === 200) {
            data.onsuccess(response.data);
        }
    } catch (e) {
        return data.onsuccess([]);
    }
})

const articleSlice = createSlice({
    name: 'article',
    initialState: initialState,
    reducers: {
        setData: (state, action) => {
            state.model[action.payload.field] = action.payload.value
        }
    },
    extraReducers: (builder) => {
        builder.addCase(listArticle.pending, (state, action) => {
            state.apiState = API_LOADING_STATUS;
        })
        builder.addCase(listArticle.fulfilled, (state, action) => {
            state.apiState = API_READY_STATUS;
        })
        builder.addCase(listArticle.rejected, (state, action) => {
            state.apiState = API_READY_STATUS;
            console.log('Error');
        })
        builder.addCase(loadModel.pending, (state, action) => {
            state.apiState = API_LOADING_STATUS;
        })
        builder.addCase(loadModel.fulfilled, (state, action) => {
            state.apiState = API_READY_STATUS;
        })
        builder.addCase(loadModel.rejected, (state, action) => {
            state.apiState = API_READY_STATUS;
            console.log('Error');
        })
        builder.addCase(createArticle.pending, (state, action) => {
            state.apiState = API_LOADING_STATUS;
        })
        builder.addCase(createArticle.rejected, (state, action) => {
            state.apiState = API_READY_STATUS;
        })
        builder.addCase(updateArticle.pending, (state, action) => {
            state.apiState = API_LOADING_STATUS;
        })
        builder.addCase(updateArticle.rejected, (state, action) => {
            state.apiState = API_READY_STATUS;
        })
       
    }
})

const articleReducer = articleSlice.reducer;

export const {setData} = articleSlice.actions;

export default articleReducer;
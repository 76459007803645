import { useState, useEffect } from "react"
import { Grid, Box, Typography, Stack, Divider} from '@mui/material';
import { nextEvents } from "../../store/web-block/reducer";
import { connect } from "react-redux";
import dayjs from 'dayjs';
import './eventosBlock.scss'

const EventosBlock = (props) => {

    const [eventos, setEventos] = useState([]); 

    useEffect(() => {
        props.dispatch(nextEvents({onsuccess: (response) => {
            setEventos(response)
        }}))
    }, []);

    const items = eventos.map((event) => {
        const fechaDesde = dayjs(event.startDate * 1000).locale('es-mx').format('DD[/]MM[/]YYYY')
        const fechaHasta = dayjs(event.endDate * 1000).locale('es-mx').format('DD[/]MM[/]YYYY')
        return <Box className="eventItem">
            <h5>{event.title} | <small> Del {fechaDesde} al {fechaHasta}</small></h5>
            <h5> {event.description}</h5>
        </Box>
    })

    

    return (
        <Grid item xs={12} className="eventosBlock">
            <Box className="eventosBlockTitle">
                <Typography component="h5">PRÓXIMOS EVENTOS</Typography>
                <Divider sx={{border: "1.5px solid #000"}}></Divider>
            </Box>

            <Stack>
                {items}
            </Stack>


        </Grid>
    )
}

export default connect(state => ({})) (EventosBlock)
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Panel from '../panel/Panel';
import MediaButton from './MediaButton';
import LinearProgress from '@mui/material/LinearProgress';
import './styles.scss'
import MediaTypeSelector from './MediaTypeSelector';
import axios from 'axios';
import { params } from '../../config/parameters'
import MediaInfoDialog from './MediaInfoDialog';
import { showAlert } from '../../store/alert/reducer';
import MediaItem from './MediaItem';


const MediaUploader = (props) => {

    const selectorRef                      = React.createRef();
    const extensiones                      = {
        1: "image/png, image/jpeg",
        2: "video/mp4, video/mpeg, video/avi",
        3: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document," +
          "application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-excel," + 
          " application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }
    const [ type, setType]                 = useState();
    const [ title, setTitle]               = useState();
    const [ openSelector, setOpenSelector] = useState(false);
    const [ selectorAccept, setSelectorAccept] = useState("");
    const [ openMediaInfo, setOpenMediaInfo] = useState(false);
    const [ uploadProgress, setUploadProgress] = useState(0);
    
    const handleClickMediaBtn = (e) => {
        setOpenSelector(true);
    }

    const handleSetMediaInfo = (mediaTitle) => {
        setTitle(mediaTitle);
        setOpenMediaInfo(false);
        selectorRef.current.click();
    }

    const handleOnTypeSelect = (type) => {
        setType(type);
        setOpenSelector(false);
        setSelectorAccept(extensiones[type]);
        setOpenMediaInfo(true);
    }

    const handleOnCloseSelector = () => {
        setOpenSelector(false);
    }

    const handleOnCloseMediaInfo = () => {
        setOpenSelector(false);
    }

    const handleUploadFile = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        
        setUploadProgress(percent);
    }

    const handleChangeFile = (e) => {
        
        const form = new FormData();
        form.append('type', type);
        form.append('media', e.target.files[0])

        axios.post(
            params.apiBaseUrl + 'backend/media',
            form,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : 'Bearer ' + props.login.token
                },
            },
            handleUploadFile
        ).then((response) => {
            props.onUpload(response.data.media, title)
        }).catch((error) => {
            props.dispatch(showAlert({message: "Error al cargar archivo: " + error.data.error, severity: "danger"}))
        }) ;

    }

    

    let media = [
        <Grid item>
            <MediaButton onClick={handleClickMediaBtn}></MediaButton>
        </Grid>
    ].concat(props.media.map((obj, index) => {
        return (<Grid item xs={12}>
            <MediaItem item={obj} index={index} onChangeTitle={props.onChangeTitle} deleteAction={props.deleteAction} itemIndex={index}></MediaItem>
        </Grid>)
    }))

    return (
       <Panel className="panelUploader" title="Archivos Multimedia" bodyStyles={{textAlign: "left"}}>
           <Box style={{paddingTop: "15px", paddingBottom: "15px"}}>
               <LinearProgress variant="determinate" value={uploadProgress}></LinearProgress>
           </Box>
           <Grid container>
            {media}
            <input type="file" id="fileSelector" onChange={handleChangeFile} ref={selectorRef} style={{display: "none"}} accept={selectorAccept}></input>
           </Grid>
           <MediaTypeSelector 
                onSelectType={handleOnTypeSelect}
                open = {openSelector}
                onClose = {handleOnCloseSelector}
            ></MediaTypeSelector>
            <MediaInfoDialog 
                open={openMediaInfo} 
                onClose={handleOnCloseMediaInfo}
                onSetData={handleSetMediaInfo}
            ></MediaInfoDialog>
       </Panel>
    )
}

export default connect(state => ({login: state.login}))(MediaUploader);
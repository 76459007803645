import { Grid, Box} from '@mui/material'
import noimage from '../../assets/images/noimage.png'
import { params } from '../../config/parameters'

const Item = (props) => {


    let image = <img src={noimage} className="integranteImg"></img>

    if (props.block && props.block.medias && props.block.medias[0]) {
        image = <img className="integranteImg" src={params.apiBaseUrl + 'api/media/' + props.block.medias[0].media.id + '/download'}></img>
    }

    return (
        <Grid item container xs={12} className="integrante">
            <Grid xs={12} md={6} xl={3}>
                {image}
            </Grid>
            <Grid xs={12} md={6} xl={9}>
                <Box className="integranteItem">
                    <h3>| {props.block ? props.block.name : '' }</h3>
                    <h2>{props.block ? props.block.title : ''}</h2>
                </Box>
            </Grid>
        </Grid>
    )
}


export default Item
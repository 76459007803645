import React from 'react';
import {BrowserRouter, Routes, Route, useBeforeUnload, useLocation} from 'react-router-dom';
import Dashboard from '../pages/admin/Dashboard';
import Home from '../pages/Home';
import Login from '../pages/admin/Login'
import PrivateRoute from './PrivateRoute';
import Categories from '../pages/admin/category/Categories';
import CategoryForm from '../pages/admin/category/CategoryForm';
import RedirectManager from '../pages/admin/components/redirect-manager/RedirectManager';
import Articles from '../pages/admin/article/Articles';
import ArticleForm from '../pages/admin/article/ArticleForm';
import ArticleView from '../pages/admin/article/ArticleView';
import WebBlockGroups from '../pages/admin/web-block-group/WebBlockGroups';
import WebBlockGroupsForm from '../pages/admin/web-block-group/WebBlockGroupsForm';
import WebBlocks from '../pages/admin/web-block/WebBlocks';
import WebBlockForm from '../pages/admin/web-block/WebBlockForm';
import Eventos from '../pages/admin/eventos/Eventos';
import Article from '../pages/article/Article';
import ArticleIndex from '../pages/article/ArticleIndex';
import EventosIndex from '../pages/eventos/EventosIndex';
import AfiliadosIndex from '../pages/afiliados/AfiliadosIndex';
import ComisionIndex from '../pages/comision/ComisionIndex';
import ArchivosIndex from '../pages/archivos/ArchivosIndex';


const Router = (props) => {

  return (
        <BrowserRouter>
          <Routes>
              <Route path="/" exact element={<Home></Home>}></Route>
              <Route path="/admin/login" exact element={<Login></Login>}></Route>
              <Route path="/admin/dashboard" element={
                <PrivateRoute>
                     <Dashboard></Dashboard>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/article" exact element={
                <PrivateRoute>
                    <Articles></Articles>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/article/:id" exact element={
                <PrivateRoute>
                    <ArticleView></ArticleView>  
                </PrivateRoute>
              }></Route>
              <Route path="/admin/article/new" exact element={
                <PrivateRoute>
                    <ArticleForm></ArticleForm>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/article/:id/update" exact element={
                <PrivateRoute>
                    <ArticleForm></ArticleForm>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/category" exact element={
                <PrivateRoute>
                    <Categories></Categories>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/category/:id/update" element={
                <PrivateRoute>
                    <CategoryForm></CategoryForm>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/category/new" exact element={
                <PrivateRoute>
                    <CategoryForm></CategoryForm>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/web-block-group" exact element={
                <PrivateRoute>
                  <WebBlockGroups></WebBlockGroups>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/web-block-group/new" exact element={
                <PrivateRoute>
                  <WebBlockGroupsForm></WebBlockGroupsForm>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/web-block-group/:id/update" exact element={
                <PrivateRoute>
                  <WebBlockGroupsForm></WebBlockGroupsForm>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/web-blocks" exact element={
                <PrivateRoute>
                  <WebBlocks></WebBlocks>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/web-blocks/:group_slug_param" exact element={
                <PrivateRoute>
                  <WebBlocks></WebBlocks>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/events" exact element={
                <PrivateRoute>
                  <Eventos></Eventos>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/comision" exact element={
                <PrivateRoute>
                  <WebBlocks group_slug="comision"></WebBlocks>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/web-blocks/new" exact element={
                <PrivateRoute>
                  <WebBlockForm></WebBlockForm>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/web-blocks/new/:group_slug" exact element={
                <PrivateRoute>
                  <WebBlockForm></WebBlockForm>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/web-blocks/:id/update" exact element={
                <PrivateRoute>
                  <WebBlockForm></WebBlockForm>
                </PrivateRoute>
              }></Route>
              <Route path="/admin/" element={
                <PrivateRoute>
                     <Dashboard></Dashboard>
                </PrivateRoute>
              }></Route>
              <Route path="/articles/" element={
                     <ArticleIndex></ArticleIndex>
              }></Route>
              <Route path="/articles/:slug" element={
                <Article></Article>
              }></Route>
              <Route path="/eventos/" element={
                <EventosIndex></EventosIndex>
              }></Route>
              <Route path="/comision/" element={
                <ComisionIndex></ComisionIndex>
              }></Route>
              <Route path="/afiliados/" element={
                <AfiliadosIndex></AfiliadosIndex>
              }></Route>
              <Route path="/archivos/" element={
                <ArchivosIndex></ArchivosIndex>
              }></Route>
          </Routes>
        </BrowserRouter>
    )
}

export default Router
import { Grid } from '@mui/material'
import './styles.scss'

const DetailView = (props) => {

    const rows = props.attributes.map((attribute) => {
        let val = '';

        if (typeof attribute.value !== 'function') {
            val = props.model[attribute.value];
        } else {
            val = attribute.value(props.model)
        }

        return (
            <Grid container>
                <Grid className="celda" item xs={6}>
                    <h4>{attribute.label}</h4>
                </Grid>
                <Grid className="celda" item xs={6}>
                    <p>{val}</p>
                </Grid>
            </Grid>
        )
    });

    return (
        <Grid className="detailView" container>
            {rows}
        </Grid>
    )

}

export default DetailView
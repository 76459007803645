import React from 'react';
import Admin from '../../layouts/admin';
import {Box} from '@mui/material'

const Dashboard = () => {
    return (
        <Admin>
            <Box style={{width: "75%", height: "200px", border: "0.5px solid #CCC", position:"relative", margin: "10% auto"}}>
                <h1>Bienvenido</h1>
                <h3>Este es el panel para administrar su sitio web</h3>
                <h3>Para continuar seleccione una opción del menú</h3>

            </Box>
        </Admin>
    )
}

export default Dashboard
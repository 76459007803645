import ColumnDerecha from "../../components/columna-derecha/ColumnaDerecha";
import MainLayout from "../../layouts/main";
import { Grid, Box, Typography, Divider } from '@mui/material'
import './styles.scss'
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { findBlockOrCreate } from "../../store/web-block/reducer";
import Item from "./Item";

const ComisionIndex = (props) => {

    const [presidente, setPresidente] = useState(undefined);
    const [vicepresidente, setVicePresidente] = useState(undefined);
    const [secretario, setSecretario] = useState(undefined);
    const [tesorero, setTesorero] = useState(undefined);
    const [revisor, setRevisor] = useState(undefined);


    const presidenteBlock = {
        slug: 'presidente',
        block: {
            name: 'Presidente',
            resume: ''
        },
        group: {
            name: 'Comision',
            has_title: true ,
            slug: 'comision'
        }
    }

    const viceBlock = {
        slug: 'vicepresidente',
        block: {
            name: 'Vice Presidente',
            resume: ''
        },
        group: {
            name: 'Comision',
            has_title: true ,
            slug: 'comision'
        }
    }

    const secretarioBlock = {
        slug: 'secretario',
        block: {
            name: 'Secretario',
            resume: ''
        },
        group: {
            name: 'Comision',
            has_title: true ,
            slug: 'comision'
        }
    }

    const tesoreroBlock = {
        slug: 'tesorero',
        block: {
            name: 'Tesorero',
            resume: ''
        },
        group: {
            name: 'Comision',
            has_title: true ,
            slug: 'comision'
        }
    }

    
    useEffect(() => {
        
        props.dispatch(findBlockOrCreate({block: presidenteBlock, onsuccess: (response) => {
            setPresidente(response);
        }}))

        props.dispatch(findBlockOrCreate({block: viceBlock, onsuccess: (response) => {
            setVicePresidente(response);
        }}))

        props.dispatch(findBlockOrCreate({block: secretarioBlock, onsuccess: (response) => {
            setSecretario(response);
        }}))

        props.dispatch(findBlockOrCreate({block: tesoreroBlock, onsuccess: (response) => {
            setTesorero(response);
        }}))
       
    }, [])


    return (
        <MainLayout>
            <Grid container  columnSpacing={{xs: 3}}>
                <Grid container item xs={12} md={8}>
                    <Box className="comisionIndexTitle">
                        <Typography variant="h4">Comisión Directiva</Typography>
                        <Divider sx={{border: "1.5px solid #000"}}></Divider>
                    </Box>
                    <Grid container item className="comisionContainer">
                        <Item block={presidente}></Item>
                        <Item block={vicepresidente}></Item>
                        <Item block={secretario}></Item>
                        <Item block={tesorero}></Item>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ColumnDerecha></ColumnDerecha>
                </Grid>
            </Grid>
        </MainLayout>
    )
}

export default connect(state => ({}))(ComisionIndex);
export const login = (data) => {
    return  {
        type: "UserLogin",
        payload: data
    }
}

export const setData = (data) => {
    return  {
        type: "UserSetData",
        payload: data
    }
}

export const logout = () => {
    return  {
        type: "UserLogout",
        payload: undefined
    }
}

export const error = (message) => {
    return  {
        type: "LoginError",
        payload: message
    }
}

export const stoppingLogin = () => {
    return {
        type: "LoginStop",
    }
}

export const initLogin = () => {
    return {
        type: "LoginInit",
    }
}
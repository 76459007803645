import React from 'react';
import './styles.scss'
import  MenuBar  from '../menu/Menu';


const Header = () => {

    return (
        <div className="Header">
            <MenuBar></MenuBar>

        </div>
    )
}

export default Header;
import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { connect } from 'react-redux';
import logo from '../../assets/logo_new.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import './styles.scss'
import { findBlockOrCreate } from '../../store/web-block/reducer';

const Footer = (props) => {

    const [asociacion, setAsociacion] = useState('');
    const [direccion, setDireccion] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');

    const asociacionBlock = {
        slug: 'footer-title',
        block: {
            name: 'Titulo del Footer',
            resume: 'Texto de encabezado del Footer'
        },
        group: {
            name: 'Footer',
            has_description: true ,
            slug: 'footer'
        }
    }

    const direccionBlock = {
        slug: 'footer-address',
        block: {
            name: 'Domicilio',
            resume: 'Domicilio que se muestra en el Footer'
        },
        group: {
            name: 'Footer',
            has_description: true ,
            slug: 'footer'
        }
    }

    const telefonoBlock = {
        slug: 'footer-phone',
        block: {
            name: 'Teléfono',
            resume: 'Teléfono que se muestra en el Footer'
        },
        group: {
            name: 'Footer',
            has_description: true ,
            slug: 'footer'
        }
    }

    const emailBlock = {
        slug: 'footer-email',
        block: {
            name: 'Email',
            resume: 'Email que se muestra en el Footer'
        },
        group: {
            name: 'Footer',
            has_description: true ,
            slug: 'footer'
        }
    }

    const instagramBlock = {
        slug: 'footer-instagram',
        block: {
            name: 'Instagram',
            resume: 'Url al perfil de instagram'
        },
        group: {
            name: 'Footer',
            has_description: true ,
            slug: 'footer'
        }
    }

    const facebookBlock = {
        slug: 'footer-facebook',
        block: {
            name: 'Facebook',
            resume: 'Url al perfil de facebook'
        },
        group: {
            name: 'Footer',
            has_description: true ,
            slug: 'footer'
        }
    }

    useEffect(() => {
        
        props.dispatch(findBlockOrCreate({block: asociacionBlock, onsuccess: (response) => {
            setAsociacion(response.description);
        }}))

        props.dispatch(findBlockOrCreate({block: direccionBlock, onsuccess: (response) => {
            setDireccion(response.description);
        }}))

        props.dispatch(findBlockOrCreate({block: telefonoBlock, onsuccess: (response) => {
            setTelefono(response.description);
        }}))

        props.dispatch(findBlockOrCreate({block: emailBlock, onsuccess: (response) => {
            setEmail(response.description);
        }}))

        props.dispatch(findBlockOrCreate({block: instagramBlock, onsuccess: (response) => {
            setInstagram(response.description);
        }}))

        props.dispatch(findBlockOrCreate({block: facebookBlock, onsuccess: (response) => {
            setFacebook(response.description);
        }}))
    }, [])

    return (<footer>
        <Grid container className="footer">
            <Grid item container xs={6}>
                <Grid item xs={12}>
                    <img className="footerLogo" src={logo}></img>
                </Grid>
                
            </Grid>
            <Grid item container xs={6}>
                <Grid item xs={12}>
                    <h5>{asociacion}</h5>
                </Grid>
                <Grid item xs={12}>
                    <h5><LocationOnIcon></LocationOnIcon> {direccion}</h5>
                </Grid>
                <Grid item xs={12}>
                    <h5><LocalPhoneIcon></LocalPhoneIcon> {telefono}</h5>
                </Grid>
                <Grid item xs={12}>
                    <h5><AlternateEmailIcon> </AlternateEmailIcon>{email}</h5>
                </Grid>
                <Grid item xs={12}>
                    <a href={instagram}>
                        <InstagramIcon></InstagramIcon>
                    </a>
                    <a href={facebook}>
                        <FacebookIcon></FacebookIcon>
                    </a>
                </Grid>
                
            </Grid>
        </Grid>
    </footer>)
}

export default connect(state => ({}))(Footer)
import ColumnDerecha from "../../components/columna-derecha/ColumnaDerecha";
import MainLayout from "../../layouts/main";
import { Grid, Typography, Divider, Box, Pagination } from '@mui/material'
import Proximamente from "../../components/proximamente/Proximamente";
import './styles.scss'
import { useEffect, useState } from "react";
import { allEvents } from "../../store/web-block/reducer";
import Evento from "./Evento";
import { connect } from "react-redux";

const EventosIndex = (props) => {

    const [ eventos, setEventos ] = useState([]);
    const [ page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setLoading(true);
        props.dispatch(allEvents({query:{page: page}, onsuccess: (response) => {
            console.log(response.blocks)
            setEventos(response.blocks)
            setCount(response.totalPages)
            setTotal(response.count)
            setLoading(false);
        }}))
    }, [page])

    const handlePagination = (event, value) => {
        setPage(value)
    }

    const items = eventos.map((ev) => {
        return <Evento event={ev}></Evento>
    })

    return (
        <MainLayout>
            <Grid container columnSpacing={{xs: 3}} >
                <Grid container item xs={12} md={8} >
                    <Box className="eventosIndexTitle">
                        <Typography variant="h4">Eventos</Typography>
                        <Divider sx={{border: "1.5px solid #000"}}></Divider>
                    </Box>
                    
                    <Grid container item xs={12} className="eventosContainer">
                        {items}
                    </Grid>

                    <Box className="paginationContainer">
                        <Pagination count={count} variant="outlined" shape="rounded" onChange={handlePagination} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ColumnDerecha></ColumnDerecha>
                </Grid>
            </Grid>
        </MainLayout>
    )
}

export default connect(state => ({}))(EventosIndex);
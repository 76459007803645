import React from 'react'


const getData = () => {
    const data = sessionStorage.getItem('authData');
    
    return data ? JSON.parse(data) : undefined;
}


const getToken = () => {
    const data = getData();
    
    if (data) {
        return data.token;
    }
    
    return undefined;
}

const userData = () => {
    const data = getData();
    
    if (data) {
        return data.user;
    }
    
    return undefined;
}

const isLogged = () => {
    const data = getData();
    
    return data !== undefined
    
}

const AuthContext = React.createContext({
    getToken: getToken,
    userData: userData,
    isLogged: isLogged
});

const AuthProvider = (props) => {

    return (
        <AuthContext.Provider value={{
            getToken: getToken,
            userData: userData,
            isLogged: isLogged
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export {
    AuthContext,
    AuthProvider
}


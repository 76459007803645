import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, TextareaAutosize, TextField } from '@mui/material';
import React, { useState } from 'react';

const MediaInfoDialog = (props) => {

    const [title, setTitle] = useState()

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    }
   
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Descripción del Archivo Multimedia
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="titleField"
                    label="Título"
                    fullWidth
                    variant="standard"
                    onChange={onChangeTitle}
                ></TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancelar</Button>
                <Button onClick={() => {props.onSetData(title)}}>Seleccionar Archivo</Button>
            </DialogActions>
        </Dialog>
    )
}

export default MediaInfoDialog
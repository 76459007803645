import { Card, CardMedia, CardContent ,Box , Grid, FormControl, InputLabel, OutlinedInput, Button} from '@mui/material';
import React from 'react';
import docicon from '../../assets/docicon.png'
import DeleteIcon from '@mui/icons-material/Delete';
import { params } from '../../config/parameters';

const MediaItem = (props) => {
    let component =  <CardMedia></CardMedia>

    let titleField = <FormControl sx={{width: "90%", margin: "10% 10%"}}>
                        <InputLabel>Título</InputLabel>
                        <OutlinedInput id="media_title" defaultValue={props.item.title} onChange={(e) => {
                            props.onChangeTitle(e.target.value, props.index);
                        }}></OutlinedInput>
                    </FormControl>

    const deleteBtn = <Button variant="contained" color="error" sx={{margin: "0% 10%"}} onClick={() => props.deleteAction(props.itemIndex)}>
        <DeleteIcon></DeleteIcon>
        Eliminar
    </Button>          

    switch(parseInt(props.item.media.type)) {
        case 5:
            component = <Box sx={{width: "100%", paddingTop: "10px", paddingRight: "10px"}}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <CardContent>
                                        {'Imagen'}
                                    </CardContent>
                                </Grid>
                                <Grid item xs={7} >
                                    <CardMedia
                                    component = "img"
                                    sx={{width: "100%", height: 250, backgroundSize: "200px 200px", margin:"10px"}}
                                    //image= {"http://media.website.local/" + props.item.media.file}
                                    image= {params.apiBaseUrl + "api/media/" + props.item.media.id + '/download'}
                                    alt= {props.item.title}
                                    ></CardMedia>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {titleField}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {deleteBtn}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
            break;
        case 10:
            component =<Box sx={{width: "100%", paddingTop: "10px", paddingRight: "10px"}}>
                            <Grid container>
                            <Grid item xs={12}>
                                    <CardContent>
                                        {'Video'}
                                    </CardContent>
                                </Grid>
                                <Grid item xs={7} >
                                    <CardMedia
                                        component = "video"
                                        sx={{width: 250, height: 250}}
                                        //src= {"http://media.website.local/" + props.item.media.file}
                                        src= {"https://media.arpgba.com/" + props.item.media.file}
                                        alt= {props.item.title}
                                        controls
                                    ></CardMedia>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {titleField}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {deleteBtn}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
            break;
        case 15:  
            component = <Box sx={{width: "100%", paddingTop: "10px", paddingRight: "10px"}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <CardContent>
                                {'Documento'}
                            </CardContent>
                        </Grid>
                        <Grid item xs={7} >
                            <CardMedia
                            component = "img"
                            sx={{width: 250, height: 250, backgroundSize: "250px 250px", margin:"10px"}}
                            image= {docicon}
                            alt= {props.item.title}
                            ></CardMedia>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container>
                                <Grid item xs={12}>
                                    {titleField}
                                </Grid>
                                <Grid item xs={12}>
                                    {deleteBtn}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            break;  
    }

    return (
        <Card>
            {component}
        </Card>
    )
};

export default MediaItem;
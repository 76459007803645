import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { findBlockOrCreate } from '../../store/web-block/reducer';
import './style.scss'
import { params } from '../../config/parameters';


const Portrait = (props) => {
    
    const [images, setImages] = useState([]);

    useEffect(() => {
        const block = {
            slug: 'slider-principal',
            block: {
                name: 'Slider Principal',
                resume: 'Slider de la página de inicio'
            },
            group: {
                name: 'General',
                has_media: true,
                slug: 'general'
            }
        }
        props.dispatch(findBlockOrCreate({block: block, onsuccess: (response) => {
            console.log(response.webBlockMedia)
            setImages(response.medias);
        }}))
    }, [])

    let image = ''
    
    if (images !== undefined && images.length > 0) {
        console.log(images)
        image = <img className="portraitImage" src={params.apiBaseUrl + 'api/media/' + images[0].media.id + '/download'}></img>
    }

    return (
        <Container className="portrait">
            {image}
        </Container>
    )
}

export default connect(state => ({})) (Portrait);
import React, { useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Navigate, useNavigate} from 'react-router-dom';
import { connect } from "react-redux";


const PrivateRoute = (props) => {

    const nav = useNavigate()
    const [ token, setToken ] = useState(localStorage.getItem('authToken'))

    useEffect(() => {
        if (token === undefined || token === '') {
            nav('/admin/login');
        }
    }, [token])

    useEffect(() => {
        window.addEventListener('storage', function(event){
            console.log('dispara storache change')
            if (event.storageArea === localStorage) {
                setToken(event.newValue);
            }
        }, false);
    }, [])
   
    if (token === undefined || token === '') {
        //console.log(localStorage.getItem('authToken'))
        return <Navigate to='/admin/login'></Navigate>

    }

    return props.children
}

export default connect(state => ({login: state.login}))(PrivateRoute)
import { combineReducers, configureStore, applyMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import alertReducer from "./alert/reducer";
import articleReducer from "./article/reducer";
import categoryReducer from "./category/reducer";
import LoginReducer from './login/reducer'
import navigatorReducer from "./navigator/reducer";
import webBlockReducer from "./web-block/reducer";

const store = configureStore(
    {
        reducer: {
            login: LoginReducer,
            category: categoryReducer,
            alert: alertReducer,
            navigator: navigatorReducer,
            article: articleReducer,
            webBlock: webBlockReducer
        }
    },
    applyMiddleware(thunk)

)


export default store
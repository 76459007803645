import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Admin from '../../../layouts/admin';
import Title from '../components/title/Title';
import Page from '../Page';
import {API_LOADING_STATUS } from '../../../config/constants';
import DataTable from '../components/table/Table';
import { listCategories } from '../../../store/category/reducer';
import { Button, colors, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const Categories = (props) => {

    const tableColumns = [
        {
            name: 'Nombre',
            value: 'name'
        },
        {
            name: 'Slug',
            value: 'slug'
        },
        {
            name: 'Mostrar en menu',
            value: (model) => {
                return model.menu ? "Si" : "No";
            }
        },
    ];
    const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)
    const [loading, setLoading] = useState(true)

    const handlePagination = (event, page) => {
        setPage(page)
    }
   

    useEffect(() => {
        setLoading(true)
        props.dispatch(listCategories({params: {query: {page: page}}, onsuccess: (response) => {
            setCategories(response.data);
            setTotalPages(response.pagination?.totalPages);
            setLoading(false)
        }}))        
    }, [page]);

    return (
        <Admin>
            <Page>
                <Title>
                    Categorias de Artículos
                </Title>

                <Stack direction="row" spacing={2}>
                    <Link to="/admin/category/new" >
                        <Button variant="contained" color="success">
                            Nueva Categoría
                        </Button>
                    </Link>
                </Stack>                


                <DataTable 
                    columns={tableColumns} 
                    data={categories} 
                    loading={loading}
                    handlePagination = {handlePagination}
                    totalPages = {totalPages}
                ></DataTable>

            </Page>
        </Admin>
    )
}

export default connect(state =>({login: state.login, categoryData: state.category}))(Categories)
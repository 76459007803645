import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    url: ''
}

export const navigatorSlice = createSlice({
    name: 'navigator',
    initialState: initialState,
    reducers: {
        redirectTo: (state, action) => {
            state.url = action.payload.data.url
        }
    }
});

const navigatorReducer = navigatorSlice.reducer

export const {redirectTo} = navigatorSlice.actions

export default navigatorReducer

